import React from 'react';
import './country-select.scss';
import PopupCloseIcon from '../../popup-close-icon';
import COUNTRIES from '../../../../default-data/countries';
import {AppContext} from "../../../../App";

class CountrySelect extends React.Component {
    countries = COUNTRIES;

    render() {
        return (
            <AppContext.Consumer>{(context) => (
                <div className="select-modal select-modal-open">
                    <div className="popup-lay">
                        <div className="content">
                            <div className="items">
                                {this.countries.map((elm) => {
                                    return <div onClick={() => {
                                        context.changeCountryValue(elm);
                                        context.toggle_popup();
                                    }}
                                                className='item'>
                                        <span>{elm.value}</span> {elm.text}
                                    </div>
                                })}
                            </div>
                            <PopupCloseIcon/>
                        </div>
                    </div>
                </div>
            )}</AppContext.Consumer>
        );
    }
}

export default CountrySelect;
