import React from 'react';
import './photo_view_popup.scss';
import PopupCloseIcon from '../popup-close-icon';

class PhotoViewPopup extends React.Component{

    render() {
        return (
            <div className="photo-view-popup">
                <div className="popup-lay">
                    <div className="content" style={{backgroundImage: 'url(' + this.props.photo_url + ')'}}>
                        <div className="content-block" />
                        <PopupCloseIcon/>
                    </div>
                </div>
            </div>

        );
    }
}
export default PhotoViewPopup;
