import React, {} from 'react';
import { Route, Switch , BrowserRouter as Router } from 'react-router-dom'
import './App.scss';
import Menu from './components/menu/menu';
import FullpageLayout from './fullpage-layout/fullpage-layout';
import Popup from './components/popup/popup';
import UnloadPopup from './components/popup/unload/unload';
import {PageView, initGA, ModalView} from './components/ga/ga';
import exitIntent from 'exit-intent';

export const AppContext = React.createContext();

class App extends React.Component{
    window_heigth = 0;
    lastHeight = window.innerHeight;

    state = {
        popup_name: '',
        show_popup: false,
        popup_view_photo_url: '',
        country_select: {text: '', value: ''},
        select_options: [],
        select_value: {value: '', belongs: null},
        change_select_options: (val, belongs) => {
            this.setState({
                select_options : {value: val, belongs: belongs},
            });
        },
        changeCountryValue: (val) => {
            this.setState({
                country_select : {text: val.value, value: val.text},
            });
        },
        change_select_value: (val) => {
            this.setState({
                select_value : val,
            });
        },
        toggle_popup: (name) => {
            this.setState({
                show_popup : !this.state.show_popup,
                popup_name : name
            })
        },
        change_popup_view_url: (val) => {
            this.setState({popup_view_photo_url : val})
        }
    };

    componentWillMount() {
        let oldY = 0;
        let move_up = false;
        let was_on_contact = false;

        let getCookie = (name) => {
            let value = "; " + document.cookie;
            let parts = value.split("; " + name + "=");
            if (parts.length === 2) return parts.pop().split(";").shift();
        };

        document.addEventListener("mousemove", (e) => {
            if(oldY > e.pageY) {
                move_up = true;
                //console.log('Event - move_up')
            } else {
                move_up = false;
               // console.log('Event - move down')
            }
            oldY = e.pageY;
        });

        // SOLUTION DETECT
        if (window.location.href.split('/#')[1] === 'solution') {
            was_on_contact = true;
            // console.log('Contact us page visited', was_on_contact)
        }
        window.onhashchange = (e) => {
            if (e.newURL.split('/#')[1] === 'solution') {
                was_on_contact = true;
               // console.log('Contact us page visited', was_on_contact)
            }
        };

        // EXIT POPUP METHODS
        setTimeout(() => {
            //console.log('Activate EXIT-POPUP after 30 secs');

            exitIntent({
                threshold: 10,
                maxDisplays: 1000,
                eventThrottle: 200,
                onExitIntent: () => {
                    // console.log('onExitIntent Fired');
                    // console.log(
                    //     'Was it fired before: ', getCookie('got_out'),
                    //     'Was contact form sent: ', getCookie('sent_contact_form'),
                    //     'Was on contact: ', was_on_contact,
                    //     'Direction move up: ', move_up
                    // );

                    if (
                        !getCookie('got_out') && !getCookie('sent_contact_form') &&
                        move_up && was_on_contact
                    ) {
                        //console.log('Display ExitIntent popup')
                        ModalView('exit_popup');
                        this.setState({
                            show_popup: true,
                            popup_name: 'exit-popup'
                        });
                        setTimeout(() => {
                            document.cookie = "got_out=yes";
                        }, 10)
                    }

                    // Prevent double event
                    move_up = false;
                }
            })
        }, 60000);

        if (/Mobi/.test(navigator.userAgent)) {
            document.getElementsByTagName('body')[0].classList.add('mobile');
                setTimeout(() => {
                    if (!getCookie('got_out') && !getCookie('sent_contact_form') && was_on_contact) {
                        ModalView('exit_popup');
                        this.setState({
                            show_popup: true,
                            popup_name: 'exit-popup'
                        });
                        setTimeout(() => {
                            document.cookie = "got_out=yes";
                        }, 10)
                    }
                }, 90000);
        }
    }

    componentDidMount() {
        initGA('UA-139739086-1');
        const { hash } = window.location;
        if (hash === '') {
            PageView('');
        }
    }

    render() {
        return (
                <AppContext.Provider value={this.state}>
                    <div className='wrapper'>
                        {!!this.props.is_unload && <UnloadPopup/>}
                        <Menu/>
                        <FullpageLayout ref="full_page" popup_is_open={this.state.show_popup}/>
                    </div>
                    {this.state.show_popup && <Popup/>}
                    {setTimeout(() => {this.state.fullpageRebuild = this.refs.full_page.fullpageApi.reBuild}) }
                </AppContext.Provider>
        );
    }
}

class AppRoutes extends React.Component{
    render() {
        return (
            <Router>
                <Switch>
                    <Route exact path="/"  component={() =><App is_unload={false} />} />
                    <Route exact path="/unload" component={() =><App is_unload={true} />} />
                </Switch>
            </Router>
        );
    }
}
export default AppRoutes;
