import React from 'react';
import './technology.scss';
import Button from '../../components/button/button';

class Technology extends React.Component{
    render() {
        return (
                <div className="container" >
                    <div>
                        <div className="block build-boxes col-lg-3 col-md-2 hidden-xm-down">
                            <div className="bg-box">
                                <img src={process.env.PUBLIC_URL +"/assets/img/technology/step_1.png"} alt="#"/>
                            </div>
                            <div className="bg-box">
                                <img src={process.env.PUBLIC_URL +"/assets/img/technology/step_2.png"} alt="#"/>
                            </div>
                            <div className="bg-box">
                                <img src={process.env.PUBLIC_URL +"/assets/img/technology/step_3.png"} alt="#"/>
                            </div>
                            <div className="bg-box">
                                <img src={process.env.PUBLIC_URL +"/assets/img/technology/step_4.png"} alt="#"/>
                            </div>
                        </div>
                        <div className="block col-lg-6 col-md-7 col-sm-6">
                            <div className="text-box">
                                Partable, pre-assembled and fully furnished 20ft or 40ft units sold, 20ft and 40ft units manufactured
                                and delivered  world-wide for hospitality and housing projects
                            </div>
                            <div className="img-box">
                                <img src={process.env.PUBLIC_URL +"/assets/img/technology/result.png"} alt="#"/>
                            </div>
                        </div>
                        <div className="block col-lg-3 col-sm-6">
                            <div className="box-group">
                                <div className="box">
                                    <div className="title">Fully equipped:</div>
                                    <div className="text-i"><i className="fas fa-home"/>Thermal and hydro isolation,
                                        doors, windows
                                    </div>
                                    <div className="text-i"><i className="fas fa-bath"/>Plumbing, wiring pre-installed
                                    </div>
                                    <div className="text-i"><i className="fas fa-wind"/>Air conditioning / heating fitted
                                    </div>
                                    <div className="text-i"><i className="fas fa-shower"/>Hot and cold water installed
                                    </div>
                                    <div className="text-i"><i className="fas fa-tv"/>Internet and cable TV ready</div>
                                </div>
                                <div className="box">
                                    <div className="title">Fully finished:</div>
                                    <div className="text-i"><i className="fas fa-mitten"/>Fully equipped and connected
                                        kitchen
                                    </div>
                                    <div className="text-i"><i className="fas fa-restroom"/>Fully furnished bathroom with
                                        hot and cold water
                                    </div>
                                    <div className="text-i"><i className="fas fa-lightbulb"/>Light fixtures, lamps</div>
                                    <div className="text-i"><i className="fas fa-couch"/>King sized bed, dining table,
                                        work desk, table, chairs
                                    </div>
                                </div>
                            </div>
                            <Button popup_name='technology' text='learn more' setPopupClass='technology-popup technology-popup-active' />
                        </div>
                    </div>
                </div>
        );
    }
}

export default Technology;
