import React from 'react';
import './technology.scss';
import Slider from "react-slick";
import PopupBackLine from '../popup-back-line';
import PopupCloseIcon from '../popup-close-icon';

class TechnologyPopup extends React.Component{
    componentDidMount() {
        this.props.popupModyfy();
        this.props.whellScroll(this.slider);
    };

    render() {
        const settings = this.props.settings;
        return (
            <div className='container'>
                <div className="elements">
                    <Slider ref={c => (this.slider = c)} {...settings} className='slick-slider'>
                        <div className="p-slide">
                            <PopupBackLine page="technology" />
                            <div className="content">
                                <div className="head-text">Residio.tech technology is most suitable for hospitality
                                    projects: homestay, hotel, resort
                                </div>
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th></th>
                                        <th>
                                            <div>Traditional <br/>
                                                construction</div>
                                        </th>
                                        <th>
                                            <div>Shipping <br/>
                                                container</div>
                                        </th>
                                        <th>
                                            <div>Prefabricated <br/>
                                                construction​</div>
                                        </th>
                                        <th className="residio-tech">
                                            <div>
                                                <img src={process.env.PUBLIC_URL +"/assets/img/table-logo.png"} alt="#"/>
                                                    Residio.tech <br/>
                                                    expandable</div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <th>Cost of construction</th>
                                        <td><img src={process.env.PUBLIC_URL +"/assets/img/popup/technology/question.png"} alt="#"/></td>
                                        <td><img src={process.env.PUBLIC_URL +"/assets/img/popup/technology/ok.png"} alt="#"/></td>
                                        <td><img src={process.env.PUBLIC_URL +"/assets/img/popup/technology/ok.png"} alt="#"/></td>
                                        <td><img src={process.env.PUBLIC_URL +"/assets/img/popup/technology/ok.png"} alt="#"/></td>
                                    </tr>
                                    <tr>
                                        <th>Time of launch</th>
                                        <td><img src={process.env.PUBLIC_URL +"/assets/img/popup/technology/error.png"} alt="#"/></td>
                                        <td><img src={process.env.PUBLIC_URL +"/assets/img/popup/technology/ok.png"} alt="#"/></td>
                                        <td><img src={process.env.PUBLIC_URL +"/assets/img/popup/technology/ok.png"} alt="#"/></td>
                                        <td><img src={process.env.PUBLIC_URL +"/assets/img/popup/technology/ok.png"} alt="#"/></td>
                                    </tr>
                                    <tr>
                                        <th>Suitability for homestay / hotel / resort hospitality project</th>
                                        <td><img src={process.env.PUBLIC_URL +"/assets/img/popup/technology/ok.png"} alt="#"/></td>
                                        <td><img src={process.env.PUBLIC_URL +"/assets/img/popup/technology/error.png"} alt="#"/></td>
                                        <td><img src={process.env.PUBLIC_URL +"/assets/img/popup/technology/ok.png"} alt="#"/></td>
                                        <td><img src={process.env.PUBLIC_URL +"/assets/img/popup/technology/ok.png"} alt="#"/></td>
                                    </tr>
                                    <tr>
                                        <th>Suitable for franchise / leasing</th>
                                        <td><img src={process.env.PUBLIC_URL +"/assets/img/popup/technology/error.png"} alt="#"/></td>
                                        <td><img src={process.env.PUBLIC_URL +"/assets/img/popup/technology/ok.png"} alt="#"/></td>
                                        <td><img src={process.env.PUBLIC_URL +"/assets/img/popup/technology/error.png"} alt="#"/></td>
                                        <td><img src={process.env.PUBLIC_URL +"/assets/img/popup/technology/ok.png"} alt="#"/></td>
                                    </tr>
                                    <tr>
                                        <th>Standardization / construction quality standards</th>
                                        <td><img src={process.env.PUBLIC_URL +"/assets/img/popup/technology/question.png"} alt="#"/></td>
                                        <td><img src={process.env.PUBLIC_URL +"/assets/img/popup/technology/ok.png"} alt="#"/></td>
                                        <td><img src={process.env.PUBLIC_URL +"/assets/img/popup/technology/ok.png"} alt="#"/></td>
                                        <td><img src={process.env.PUBLIC_URL +"/assets/img/popup/technology/ok.png"} alt="#"/></td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className="text-blue">Residio.tech products meet requirements for 4 star
                                    accommodation, can be leased and removed after lease period is over​
                                </div>
                            </div>
                        </div>

                        <div className="p-slide">
                            <PopupBackLine page="technology" />
                            <div className="content">
                                <div className="head-text">Residio.tech expandable units are finished to a high
                                    standard
                                </div>
                                <div className="photos">
                                    <div className="photo"/>
                                    <div className="photo"/>
                                </div>
                            </div>
                        </div>
                    </Slider>
                    <PopupCloseIcon/>
                </div>
            </div>
        );
    }
}
export default TechnologyPopup;
