import React from 'react';
import './privacy-info-popup.scss';
import PopupBackLine from '../../popup-back-line';
import PopupCloseIcon from '../../popup-close-icon';

class ContactPrivacyPopup extends React.Component{
    componentDidMount() {
        document.querySelector('.privacy-info-popup').addEventListener('wheel', ((e) =>{
            let elem = document.querySelector('.privacy-info-popup .content-block');
            if(e.deltaY < 0)  {
                elem.scrollTop -= 40;
            } else {
                elem.scrollTop += 40;
            }
        }));
    }

    render() {
        return (
            <div className="privacy-info-popup privacy-info-popup-open">
                <div className="popup-lay">
                    <div className="content">
                        <PopupBackLine page="contact us" />
                        <div className="content-block">
                            <div className="head-text">Privacy Statement</div>
                            <div className="subhead-text">Last updated: April 25, 2019</div>
                            <div className="body-text">This privacy statement describes how Residio.tech collects, uses,
                                protects and
                                shares the personal information of our customers. Customers include those who visit our
                                resorts and
                                hotels, use our websites and mobile apps, and otherwise interact with us.
                            </div>
                            <div className="body-text">This initial section describes how Residio.tech collects, uses,
                                protects and
                                shares customer information.
                            </div>
                            <div className="body-text">The data controller of your personal information is the
                                Residio.tech entity in
                                the jurisdiction where your personal information is collected. Please note that in some
                                countries,
                                there may be an additional entity that is the data controller.
                            </div>
                            <div className="body-text">Many of our resorts and hotels are owned and operated by
                                franchisees, who are
                                independent businessmen and women. This privacy statement does not apply to our
                                franchisees or to
                                websites or mobile apps they operate. Please see our franchisees’ privacy notices for
                                information on
                                how they use customer information.
                            </div>
                            <div className="subhead-text">1. Information We Collect</div>

                            <div className="body-text">We may collect personal information about you when you visit our
                                resorts or
                                hotels, use our websites or mobile apps (“online services”), and otherwise interact with
                                us
                                (collectively, “services”). The information we collect falls into three categories: (a)
                                information
                                you provide us; (b) information we collect through automated methods, and (c)
                                information we collect
                                from other sources.
                            </div>
                            <div className="body-text">Generally, your providing of your personal information is
                                voluntary. However,
                                there may be situations where your providing of personal information is necessary to
                                provide a
                                service or is required by law. Please note that in certain cases, we may be unable to
                                provide you
                                with our services unless you provide the information. We will let you know when the
                                providing of
                                your personal information is necessary.
                            </div>
                            <div className="body-text">We may combine the information you provide us, with information
                                that is collected
                                through automated methods, and with information we receive from other sources.
                            </div>
                            <div className="body-text"><i>We collect information you provide us.</i></div>
                            <div className="body-text">You may provide the following information to us, depending on how
                                you
                                interact with us:
                            </div>
                            <div className="body-text">
                                <ul>
                                    <li>Personal details, such as your name, postal and email addresses, phone number,
                                        birthday
                                        information and other contact information, when you register with our online
                                        services,
                                        log-in to Wi-Fi, enter one of our competitions, or contact us by phone or
                                        through our online
                                        services;
                                    </li>
                                    <li>Transaction information, including information about the products you buy,
                                        prices, method of
                                        payment and payment details;
                                    </li>
                                    <li>Account information, such as your username or password (or anything else that
                                        identifies
                                        you) used to access our online services or to buy or use our products and
                                        services;
                                    </li>
                                    <li>Profile information, including products and services you like, or times you
                                        prefer to visit
                                        us; and
                                    </li>
                                    <li>Other personal information you choose to provide us when you interact with us.
                                    </li>
                                </ul>
                            </div>

                            <div className="body-text"><i>We collect information through automated methods.</i></div>
                            <div className="body-text">We may use automated technology to collect information from your
                                computer system
                                or mobile device when you visit our resorts or hotels, use our online services, or
                                in-hotel
                                technology. Automated technology may include cookies, local shared objects, and web
                                beacons. There
                                is more information below about cookies and other technologies in Section 6.
                            </div>
                            <div className="body-text">We may collect information about your:</div>
                            <div className="body-text">
                                <ul>
                                    <li>internet protocol (IP) address;</li>
                                    <li>date and time of access of our online services or in-hotel technology;</li>
                                    <li>name and URL of the file retrieved when you use our online services;</li>
                                    <li>computer or mobile-device operating system and browser type;</li>
                                    <li>type of mobile device and its settings;</li>
                                    <li>unique device identifier (UDID) or mobile equipment identifier (MEID) for your
                                        mobile
                                        device;
                                    </li>
                                    <li>device and component serial numbers;advertising identifiers (for example, IDFAs
                                        and IFAs) or
                                        similar identifiers;
                                    </li>
                                    <li>referring website (a site that has led you to ours) or application; and</li>
                                    <li>activity related to how you use our online services, such as the pages you visit
                                        on our
                                        sites or in our mobile apps.
                                    </li>
                                </ul>
                            </div>
                            <div className="body-text">Our online services and in-hotel technology may collect
                                information about the
                                exact location of your mobile device or computer using geolocation and technology such
                                as GPS,
                                Wi-Fi, Bluetooth, or cell tower proximity. For most mobile devices and computer systems,
                                you will be
                                requested to give your permission for Residio.tech to process this information. You are
                                able to
                                withdraw your permission for us to collect this information by using the device or
                                web-browser
                                settings. If you have any questions about how to prevent us from collecting exact
                                information about
                                your location, we recommend you contact your mobile-device service provider, the device
                                manufacturer, or your web-browser provider. Some online services and in-hotel technology
                                may not
                                work properly without information about your location. If you would like us to delete
                                information we
                                have collected which could identify your location, please contact us at our Global or
                                Local Data
                                Protection Office using the contact information provided below. By law, we may need to
                                keep certain
                                information.
                            </div>
                            <div className="body-text"><i>We collect information from other sources.</i></div>
                            <div className="body-text">We may collect information about you from other companies and
                                organizations,
                                including public databases, social media platforms, or third party partners such as
                                analytics or marketing providers. We may also collect information that is publicly
                                available. For
                                example, we may collect public profile information about you when you interact with us
                                through
                                social media. We may also collect communications to us or regarding us on social media.
                            </div>
                            <div className="body-text">We may combine the information you provide us, with information
                                that is collected
                                through automated methods, and with information we receive from other sources.
                            </div>
                            <div className="subhead-text">2. How We Use The Information We Collect</div>
                            <div className="body-text">We may use the information we collect in the following ways.
                            </div>
                            <div className="body-text">To provide our services and contract with you:</div>
                            <div className="body-text">
                                <ul>
                                    <li>carry out your requests, fulfill orders, and process payments for our products
                                        and services;
                                    </li>
                                    <li>communicate with you about your orders, purchases or accounts with us, requests,
                                        questions, and comments;
                                    </li>
                                    <li>provide online services to you, which includes our websites or mobile apps;
                                        and
                                    </li>
                                    <li>provide customer support, including to process any concerns about our
                                        services.
                                    </li>
                                </ul>
                            </div>
                            <div className="body-text">To market to you, improve our services, and the following
                                additional legitimate business interests:
                            </div>
                            <div className="body-text">
                                <ul>
                                    <li>tell you about our products and services, competitions, offers, promotions or
                                        special events that we believe may interest you;
                                    </li>
                                    <li>tell you about the products and services of our business partners;</li>
                                    <li>personalize your experience in our hotels and resort and on our online
                                        services;
                                    </li>
                                    <li>manage our business, including developing new products and services, conducting
                                        consumer and operations research, and assessing the effectiveness of our sales,
                                        marketing, and advertising;
                                    </li>
                                    <li>use analytics and profiling technology to personalize your experience, deliver
                                        content (including advertising) tailored to your interests and how you use our
                                        online services or in-store technologies, manage our business, help diagnose
                                        technical and service issues, administer our online services and in-store
                                        technologies, identify users of our online services, identify a device for fraud
                                        prevention purposes, gather demographic information about our customers, and
                                        determine usage patterns of our services;
                                    </li>
                                    <li>maintain, manage, and improve our products, offers, promotions, and online
                                        services and other technology;ensure the security of our networks and systems.
                                    </li>
                                </ul>
                            </div>
                            <div className="body-text">To comply with applicable law:</div>
                            <div className="body-text">
                                <ul>
                                    <li>protect against, identify and prevent fraud and other crime, claims and other
                                        liabilities;
                                    </li>
                                    <li>comply with legal obligations and our policies;</li>
                                    <li>establish, exercise or defend a legal claim; and</li>
                                    <li>monitor and report compliance issues.</li>
                                </ul>
                            </div>
                            <div className="body-text">With your consent (where required by applicable law), we may use
                                the information we collect for the following purposes:
                            </div>
                            <div className="body-text">
                                <ul>
                                    <li>to send you e-mails or text messages about our products and services,
                                        competitions, offers, promotions or special events that we believe may interest
                                        you;
                                    </li>
                                    <li>to send you e-mails or text messages about the products and services of our
                                        business partners;
                                    </li>
                                    <li>provide location-based services;</li>
                                    <li>provide online services to children (if parental consent is provided);</li>
                                    <li>deploy cookies and similar technologies; and</li>
                                    <li>provide online services to you, which includes our websites or mobile apps.</li>
                                </ul>
                            </div>
                            <div className="body-text">We may use the information we collect about you in other ways,
                                which we will tell you about at the time we collect it or for which we will seek your
                                consent.
                            </div>
                            <div className="subhead-text">3. How We Share The Information We Collect</div>
                            <div className="body-text">We do not sell your personal information and only share your
                                information as described in this privacy statement
                            </div>
                            <div className="body-text">We may share your personal information within the Residio.tech
                                Family. The Residio.tech Family includes Residio.tech, our affiliates, our subsidiaries,
                                and our franchisees. Members of the Residio.tech Family who receive this information
                                from us are not authorized to use or share the information, except as set out in this
                                privacy statement.
                            </div>
                            <div className="body-text">We may share your personal information with vendors who provide
                                services to us, such as fulfilling orders, providing data processing and other
                                information technology services, managing promotions, contests, prize draws and
                                sweepstakes, carrying out research and analysis, and personalizing individual
                                Residio.tech customer experiences. We do not allow these vendors to use this information
                                or to share it for any purpose other than to provide services on our behalf.
                            </div>
                            <div className="body-text">We may, for strategic or other business reasons, decide to sell
                                or transfer all or part of our business. As part of that sale or transfer, we may pass
                                information we have collected and stored, including personal information, to anyone
                                involved in the sale or transfer.
                            </div>
                            <div className="body-text">There may be times where we may share information when it does
                                not directly identify you. For example, we may share anonymous, aggregated statistics
                                about your use of our online services. Or we may combine information about you with
                                other customers and share the information in a way that does not link to a specific
                                customer.
                            </div>
                            <div className="body-text">We have the right to use or share personal information as
                                necessary to keep to any law, regulation or legal request, to protect our online
                                services and in-hotel technology, to bring or defend legal claims, to protect the
                                rights, interests, safety and security of our organization, our employees or
                                franchisees, or members of the public, or in connection with investigating fraud or
                                other crime, or violations of our policies.
                            </div>
                            <div className="subhead-text">4. Children's Privacy Notice</div>
                            <div className="body-text">We understand how important it is to protect your privacy when
                                you use our online services. We are especially committed to protecting the privacy of
                                children who visit or use our online services. For more information on how a specific
                                country protects children’s privacy, please see the country specific addenda below.
                            </div>
                            <div className="body-text">We urge parents to regularly monitor and supervise their
                                children's online activities. If you have any questions about our children’s privacy
                                practices, please contact us at our Global or Local Data Protection Office using the
                                contact information provided below. If you are contacting a Local Data Protection
                                Office, please choose the office in the country in which you are a customer.
                            </div>

                            <div className="subhead-text">5. Your Choices</div>
                            <div className="body-text"><i>Marketing Communication</i></div>
                            <div className="body-text">If you have agreed to receive marketing communications from us,
                                you can later opt out by following the opt-out instructions in the marketing
                                communications we send you. You can also generally find your communication preferences
                                with instructions on how to opt out in the profile section of the online services that
                                you use. You may also have the ability to change your communication preferences using
                                your device settings. You can also opt out by contacting us at our Global or Local Data
                                Protection Office using the contact information provided below. If you are contacting a
                                Local Data Protection Office, please choose the office in the country in which you are a
                                customer.
                            </div>
                            <div className="body-text">If you do opt out of receiving marketing communications from us,
                                we may still send communications to you about your transactions, any accounts you have
                                with us, and any contests, competitions, prize draws or sweepstakes you have entered.
                                Opting out of one form of communication does not mean you have opted out of other forms
                                as well. For example, if you opt out of receiving marketing emails, you may still
                                receive marketing text messages if you have opted in to receiving them. Please note that
                                if you are receiving communications from a Residio.tech franchise, then you will need to
                                opt out from them directly.
                            </div>
                            <div className="body-text">We do not share personal information with third parties for their
                                own direct marketing purposes, unless you give us permission to do so. When we give you
                                notice, and you consent, we will share your personal information as you direct us to.
                            </div>
                            <div className="body-text"><i>Your Personal Information Rights</i></div>
                            <div className="body-text">In certain countries, individuals are entitled to the right to
                                access, correct, transmit, restrict, delete and object to processing of the personal
                                information we have collected. In these certain countries, individuals are also entitled
                                to withdraw consent to processing of personal information. For more information
                                regarding these rights, and the countries where these rights are available, please see
                                the country specific addenda below. You can also visit the <a
                                    href="https://corporate.mcdonalds.com/corpmcd/gdpr-rights-center.html"
                                    target="_blank" rel="noopener noreferrer">GDPR Rights Center</a>.
                            </div>

                            <div className="subhead-text">6. Use of Our Online Services and Other Technology</div>
                            <div className="body-text">We, and our vendors who provide services to us, may use cookies,
                                web beacons and other similar technologies on our online services and in other areas
                                related to our business, such as online advertising, to collect information and provide
                                you with the services or products that you have requested.
                            </div>
                            <div className="body-text"><i>Cookies and other technologies</i></div>
                            <div className="body-text">A “cookie” is a small text file that is placed onto an Internet
                                user’s web browser or device and is used to remember and/or obtain information about the
                                user. Some countries in which we operate may have a cookies policy. That specific
                                information, by country, is provided below.
                            </div>
                            <div className="body-text">A “web beacon” is a small object or image that is embedded into a
                                web page, application, or email and is used to track activity. They are also sometimes
                                referred to as pixels and tags.
                            </div>
                            <div className="body-text">Please note the following:</div>
                            <div className="body-text">
                                <ul>
                                    <li>You might be assigned a cookie when using our online services</li>
                                    <li>We offer certain features that are available only through the use of cookies and
                                        other similar technologies.
                                    </li>
                                    <li>We may use both session (for the duration of your visit) and persistent (for the
                                        duration of a fixed period of time) cookies and other tracking technologies.
                                    </li>
                                    <li>Our online services and other areas related to our business may have web
                                        beacons.
                                    </li>
                                </ul>
                            </div>
                            <div className="body-text">We use cookies, web beacons and other similar technology, to
                                collect information for the purposes described in this privacy statement. We may also
                                combine the information collected by these technologies with information we have
                                collected about you by other means that are described in this privacy statement.
                            </div>
                            <div className="body-text">We may use these technologies to:</div>
                            <div className="body-text">
                                <ul>
                                    <li>uniquely identify you or your device;</li>
                                    <li>allow you to access and use our online services, where without them, our online
                                        services may not work properly;
                                    </li>
                                    <li>further system security where appropriate;</li>
                                    <li>statistical purposes, in order to measure use of our websites and mobile apps;
                                    </li>
                                    <li>improve our products and services;</li>
                                    <li>help us monitor the performance (e.g., traffic, errors, page load time, popular
                                        sections, etc.) of our online services;
                                    </li>
                                    <li>remember you, for your convenience, when you visit our online services</li>
                                    <li>help customize your experience;</li>
                                    <li>to market to you through targeted advertising; and</li>
                                    <li>for other purposes described in the section of this privacy statement titled,
                                        “How we use the information we collect.”
                                    </li>
                                </ul>
                            </div>
                            <div className="body-text">For example, we may use certain technologies to determine whether
                                you have opened an e-mail or clicked on a link contained in an e-mail, how you use the
                                pages and content in our mobile apps, or whether you have clicked on a Residio.tech
                                online advertisement.
                            </div>
                            <div className="body-text">Both we and others (such as our advertising networks) may use
                                these technologies to collect information about your online activities, over time and
                                across third-party websites and devices, and when using our online services to further
                                personalize your experience with us.
                            </div>
                            <div className="body-text">Use the options in your web browser if you do not wish to receive
                                a cookie or if you wish to set your browser to notify you when you receive a cookie.
                                Click on the “Help” section of your browser to learn how to change your cookie
                                preferences. If you disable all cookies, you may not be able to take advantage of all
                                the features available on a website
                            </div>
                            <div className="body-text">Some newer web browsers may have a "Do Not Track" preference that
                                transmits a "Do Not Track" header to the websites you visit with information indicating
                                that you do not want your activity to be tracked. Residio.tech does not currently take
                                actions to respond to Do Not Track signals because a uniform technological standard has
                                not yet been developed. We continue to review new technologies and may adopt a standard
                                once one is created.
                            </div>
                            <div className="body-text">Where video is available on our online services, we may target
                                and track the videos you view. You consent to our tracking of your video viewing through
                                online services or third-party social media for up to two years, or as otherwise
                                permitted by applicable law, or until you withdraw your consent.
                            </div>
                            <div className="body-text">More information regarding how cookies and technology are used in
                                a country in which you are a customer may be available in the country specific addenda.
                            </div>
                            <div className="body-text"><i>Targeted advertising</i></div>
                            <div className="body-text">When you use our online services, we (and our vendors who provide
                                services to us) may collect information about your activities so that we can provide you
                                with advertising tailored to your interests.
                            </div>
                            <div className="body-text">Because we take part in advertising (“ad”) networks, you may see
                                certain ads on other websites. Ad networks allow us to target the information we send
                                you based on your interests, other information related to you, and contextual means.
                                These ad networks track your online activities over time by collecting information
                                through use of cookies, web beacons, and web-server logs. The ad networks use this
                                information to show you advertisements that may be of particular interest to you. The ad
                                networks we take part in may collect information about your visits to

                                websites that also take part in the relevant ad network, such as the pages or
                                advertisements you view and how you use the websites. We use this information, both on
                                our online services and on third-party websites that take part in the ad networks, to
                                provide you with advertising tailored to you, and to help us assess how effective our
                                marketing is.
                            </div>
                            <div className="body-text">You can opt out of targeted advertising. If you choose to opt
                                out, you will continue to receive advertisements but they will not be tailored to your
                                interests.
                            </div>
                            <div className="subhead-text">7. Links to Other Websites and Social Media</div>
                            <div className="body-text">Our online services may offer links to websites that are not run
                                by us but by third parties. If you visit one of these linked websites, you should read
                                the website’s privacy policy, terms and conditions, and their other policies. We are not
                                responsible for the policies and practices of third parties. Any information you give to
                                those organizations is dealt with under their privacy policy, terms and conditions, and
                                other policies.
                            </div>
                            <div className="body-text">We may also have providers of other apps, tools, widgets and
                                plug-ins on our online services, such as Facebook “Like” buttons, which may also use
                                automated methods to collect information about how you use these features. These
                                organizations may use your information in line with their own policies.
                            </div>
                            <div className="subhead-text">8. Information Security</div>
                            <div className="body-text">We are committed to taking appropriate measures designed to keep
                                your personal information secure. Our technical, organizational and physical procedures
                                are designed to protect personal information from accidental, unlawful or unauthorized
                                loss, access, disclosure, use, alteration, or destruction. While we make efforts to
                                protect our information systems, no website, mobile application, computer system, or
                                transmission of information over the internet or any other public network can be
                                guaranteed to be 100% secure.
                            </div>
                            <div className="subhead-text">9. Retention</div>
                            <div className="body-text">We keep your information for the length of time needed to carry
                                out the purposes outlined in this privacy statement and to adhere to our policies on
                                keeping records (unless a longer period is needed by law). Our records policies reflect
                                applicable laws. We will retain and use your information to the extent necessary to
                                comply with our legal obligations (for example, if we are required to retain your
                                information to comply with applicable tax/revenue laws), resolve disputes, enforce our
                                agreements, and as otherwise described in this statement.
                            </div>
                            <div className="subhead-text">10. International Data Transfers</div>
                            <div className="body-text">Residio.tech is a global organization with business processes,
                                management structures and technical systems that cross borders. As such, we may share
                                information about you within the Residio.tech Family and transfer it to countries in the
                                world where we do business in connection with the uses identified above. Any
                                international data transfers will be in accordance with this Privacy Statement and in

                                compliance with applicable laws. Some countries in which we operate may have
                                requirements pertaining to international data transfers.
                            </div>
                            <div className="subhead-text">11. Changes To Our Privacy Statement</div>
                            <div className="body-text">This privacy statement is in effect as of the date noted at the
                                top of the statement. We may change this privacy statement from time to time. If we do,
                                we will post the revised version here and change the “last updated date” (the date it
                                applies from) at the top of the statement. You should check here regularly for the most
                                up-to-date version of the statement.
                            </div>
                            <div className="subhead-text">12. How To Contact Us</div>
                            <div className="body-text">You can contact us at any time about Residio.tech privacy
                                practices at our Global or Local Data Protection Offices. Our Local Data Protection
                                Offices can assist with country-specific queries or information. Contact information for
                                our Local Data Protection Offices can be found in the country specific addenda
                            </div>
                            <div className="body-text"><i>
                                Residio.tech is trading name of Open Keys Ltd.;<br/>
                                239/160 ifield Bangna Village, Kanchanapisek Rd., Dokmai, Prawet,<br/>
                                Bangkok (10250), Thailand<br/>
                                <strong>Email:</strong> info@residio.tech
                            </i></div>
                        </div>
                        <PopupCloseIcon/>
                    </div>
                </div>
            </div>

    );
    }
}
export default ContactPrivacyPopup;
