import React from 'react';
import axios from 'axios';
import './contact.scss';
import COUNTRIES from '../../default-data/countries';
import RadioInput from '../../components/radio-input/radio-input';
import CustomSelect from '../../components/select/select';
import {AppContext} from "../../App";
import FileUpload from '../../components/file-upload/file-upload';
import {Event, ModalView} from "../../components/ga/ga";

class Contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            fields: {
                name: '',
                email: '',
                country_select: '',
                country: '',
                phone: ''
            },
            button: 'SEND',
            errors: {}
        };
        this.attachmentsElement = React.createRef();
        this.handleChange = this.handleChange.bind(this);

    }

    static getDerivedStateFromProps(props, state) {
        if(!!props.countrySelect.text){
            state.fields.country_select = props.countrySelect.text;
            // state.fields.phone = props.countrySelect.text;
        }
        if( props.context.select_options.belongs === 'project_type'){
            state.fields.project_type = props.context.select_value;
        }
        if( props.context.select_options.belongs === 'rooms'){
            state.fields.rooms = props.context.select_value;
        }
        if( props.context.select_options.belongs === 'financing'){
            state.fields.financing = props.context.select_value;
        }
        if( props.context.select_options.belongs === 'land'){
            state.fields.land = props.context.select_value;
        }
        if( props.context.select_options.belongs === 'cooperation_type'){
            state.fields.cooperation_type = props.context.select_value;
        }
        if( props.context.select_options.belongs === 'interested'){
            state.fields.interested = props.context.select_value;
            const moving_path = document.querySelectorAll('.contact-block .dynamic-block .moving-block')[0];
            const attach_path = document.querySelector('.contact-block .attach_block');

            document.querySelectorAll('.contact-box')[0].style.backgroundColor = 'rgba(0, 0, 0, 0.95)';

            if(props.context.select_value === 'Franchise') {
                let elm = document.getElementById("Franchise");
                if(elm) {elm.checked = true;
                moving_path.style.transform = 'translate3d(100%, 0, 0)';
                attach_path.classList.remove('hidden_attachment');}
            } else if(props.context.select_value === 'Partnership') {
                let elm = document.getElementById("Partnership");
                if(elm) {elm.checked = true;
                moving_path.style.transform = 'translate3d(220%, 0, 0)';
                attach_path.classList.remove('hidden_attachment');}
            } else if(props.context.select_value === 'Forward information') {
                let elm = document.getElementById("Forward");
                if(elm) elm.checked = true;
                moving_path.style.transform = 'translate3d(340%, 0, 0)';
                attach_path.classList.add('hidden_attachment');
            }else if(props.context.select_value === 'Other inquiry') {
                let elm = document.getElementById("Other");
                if(elm) {elm.checked = true;
                moving_path.style.transform = 'translate3d(460%, 0, 0)';
                attach_path.classList.add('hidden_attachment');}
            }

            if(props.context.select_value === 'Franchise') {
                moving_path.classList.add('franchising-active');
            } else{
                moving_path.classList.remove('franchising-active');
            }
        }
        return state;
    }

    context;
    files = [];

    options = COUNTRIES;
    dropDownChange = (e) => {
        let fields = {...this.state.fields};
        if (e.name === 'country_select') {
            fields[e.name] = e.value;
            fields['phone'] = e.value;
            fields['country'] = e.text;
        } else {
            fields[e.name] = e.value;
        }
        this.setState({
            fields
        });
    };


    handleChange(e) {
        const fields = {...this.state.fields};
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        });
    }

    validateForm() {

        let fields = this.state.fields;
        let errors = {};
        let formIsValid = true;
        if (!fields["name"]) {
            formIsValid = false;
            errors["name"] = "*Please enter your name.";
        }
        if (!fields["country_select"]) {
            formIsValid = false;
            errors["country_select"] = "*Please select country.";
        }
        if (!fields["phone"]) {
            formIsValid = false;
            errors["phone"] = "*Please enter your phone number.";
        }
        if (!fields["email"]) {
            formIsValid = false;
            errors["email"] = "*Please enter your email.";
        }

        if (typeof fields["email"] !== "undefined") {
            //regular expression for email validation
            const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields["email"])) {
                formIsValid = false;
                errors["email"] = "*Please enter valid email.";
            }
        }

        this.setState({
            errors: errors
        });
        return formIsValid;
    }

    componentDidMount() {
        const input_path = document.querySelectorAll('.contact-block .radio-group input');
        const moving_path = document.querySelectorAll('.contact-block .dynamic-block .moving-block')[0];
        const attach_path = document.querySelector('.contact-block .attach_block');
        input_path[0].addEventListener('change', () => {
            Event('Contact Us', 'Click', 'Franchise');
            moving_path.style.transform = 'translate3d(100%, 0, 0)';
            document.querySelectorAll('.contact-box')[0].style.backgroundColor = 'rgba(0, 0, 0, 0.95)';
            attach_path.classList.remove('hidden_attachment');
        });
        input_path[1].addEventListener('change', () => {
            Event('Contact Us', 'Click', 'Partnership');
            moving_path.style.transform = 'translate3d(220%, 0, 0)';
            document.querySelectorAll('.contact-box')[0].style.backgroundColor = 'rgba(0, 0, 0, 0.95)';
            attach_path.classList.remove('hidden_attachment');
        });
        input_path[2].addEventListener('change', () => {
            Event('Contact Us', 'Click', 'Forward information');
            moving_path.style.transform = 'translate3d(340%, 0, 0)';
            document.querySelectorAll('.contact-box')[0].style.backgroundColor = 'rgba(0, 0, 0, 0.95)';
            attach_path.classList.add('hidden_attachment');
        });
        input_path[3].addEventListener('change', () => {
            Event('Contact Us', 'Click', 'Other inquiry');
            moving_path.style.transform = 'translate3d(460%, 0, 0)';
            document.querySelectorAll('.contact-box')[0].style.backgroundColor = 'rgba(0, 0, 0, 0.95)';
            attach_path.classList.add('hidden_attachment');
        });
    }

    handleSubmit(event) {
        event.preventDefault();

        // Prevent sending form twice
        if (this.state.button !== "SEND") {
            return false;
        }

        const context = this.props.context;
        const data = new FormData(event.target);
        const url = process.env.REACT_APP_API_URL + '/contact/';
        if (!!document.querySelector('.contact-block #Partnership:checked')
            || !!document.querySelector('.contact-block #Franchise:checked')) {
            for (const file of this.files) {
                data.append('attachments[]', file);
            }
        }
        if (this.validateForm()) {
            Event('Contact Us', 'Click', 'Send');

            this.setState({
                button: 'SENDING... '
            });

            axios({
                url: url,
                method: 'POST',
                data: data,
                onUploadProgress: (p) => {
                    this.setState({
                        button: 'SENDING... ' + Math.ceil(100 * p.loaded / p.total) + '%'
                    })
                }
            }).then((response) => {
                document.cookie = "sent_contact_form=yes";
                ModalView('contact/thank_you');
                context.toggle_popup('form_submitted');

                // Reset form
                document.querySelector('.contact-block .dynamic-block .moving-block').style.transform = 'translate3d(0, 0, 0)';
                document.querySelector('.contact-box').style.backgroundColor = 'rgba(0, 0, 0, 0.4)';
                document.querySelector('.contact-block .attach_block').classList.add('hidden_attachment');
                document.querySelector('.contact-block form').reset();

                this.setState({
                    fields: {
                        name: '',
                        email: '',
                        country_select: '',
                        country: '',
                        phone: ''
                    },
                    button: 'SEND',
                    errors: {}
                });
                this.attachmentsElement.current.clearState();
            }).catch((error) => {
                document.cookie = "sent_contact_form=yes";
                ModalView('contact/sending_error');
                context.toggle_popup('form_error');

                this.setState({
                    button: 'SEND',
                    errors: {}
                });
            });
        }
    }

    handleFieldChange = (e) => {
        Event('Contact Us', 'Click', 'Add attachments');
        this.files = e;
    };

    render() {
        const {context} = this.props;

        return (
            <div className='container'>
                <form id="ajaxForm" type="post" onSubmit={(event) => {
                    // this.context = context;
                    this.handleSubmit(event)
                }}>
                    <div className="input-lay">
                        <div className="text">Name</div>
                        <input name="name" type="text"
                               className={"form-control input-my " + (this.state.errors.name ? 'input-error' : 'error')}
                               placeholder="Enter your name" value={this.state.fields.name}
                               onChange={this.handleChange}/>
                        <div className="errorMsg text">{this.state.errors.name}</div>
                    </div>
                    <div className="group-inputs-white">
                        <div className="input-lay col-6">
                            <div className="text">Email</div>
                            <input name="email" type="text"
                                   className={"form-control input-my " + (this.state.errors.email ? 'input-error' : 'error')}
                                   value={this.state.fields.email}
                                   onChange={this.handleChange}
                                   placeholder="Enter your email"/>
                            <div className="errorMsg text">{this.state.errors.email}</div>
                        </div>
                        <div
                            className={"input-lay col-3 phone-select " + (this.state.errors.country_select ? 'select-error' : 'sucss')}>
                            <div className="text">Telephone</div>
                            <CustomSelect options={this.options} name='country_select'
                                          value={this.state.fields.country_select}
                                          placeholder='Choose your country' selectChange={this.dropDownChange}
                            />
                            <div className="select-wrapp" onClick={() => {
                                ModalView('contact/country-select');
                                context.toggle_popup('country-select');
                            }}/>
                            <div className="errorMsg text">{this.state.errors.country_select}</div>
                        </div>
                        <div className="input-lay col-3">
                            <div className="text"/>
                            <input name="country" type="hidden" value={this.state.fields.country} className="form-control input-my country-input"/>
                            <input name="phone" type="text"
                                   placeholder="Enter your telephone"
                                   className={"form-control input-my phone-input " + (this.state.errors.phone ? 'input-error' : 'error')}
                                   value={this.state.fields.phone}
                                   onChange={this.handleChange}
                            />
                            <div className="errorMsg text">{this.state.errors.phone}</div>
                        </div>
                    </div>
                    <div
                        className={"input-lay col-12 radio-select"}>
                        <div className="text">Interested in</div>
                        <CustomSelect placeholder='Select' name='project_type'
                                      selectChange={this.dropDownChange}
                                      value={this.state.fields.interested}
                                      options={[
                                          {value: 'Franchise', text: 'Franchise'},
                                          {value: 'Partnership', text: 'Partnership'},
                                          {value: 'Forward information', text: 'Forward information'},
                                          {value: 'Other inquiry', text: 'Other inquiry'}
                                      ]}
                        />
                        <div className="select-wrapp" onClick={() => {
                            ModalView('contact/country-select');
                            context.change_select_options([
                                {text: 'Franchise'},
                                {text: 'Partnership'},
                                {text: 'Forward information'},
                                {text: 'Other inquiry'},
                            ], 'interested');
                            context.toggle_popup('popup-select');
                        }}/>
                    </div>
                    <div className="group-radio-white">
                        <div className="radio-group input-lay col-3">
                            <div className="text">Interested in</div>
                            <RadioInput id='Franchise' text='Franchise'/>
                        </div>
                        <div className="radio-group input-lay col-3">
                            <div className="text"/>
                            <RadioInput id='Partnership' text='Partnership'/>
                        </div>
                        <div className="radio-group input-lay col-3">
                            <div className="text"/>
                            <RadioInput id='Forward' text='Forward information'/>
                        </div>
                        <div className="radio-group input-lay col-3">
                            <div className="text"/>
                            <RadioInput id='Other' text='Other inquiry'/>
                        </div>
                    </div>


                    <div className="dynamic-block">
                        <div className="moving-block">
                            <div className="franchise-block">
                                <div className="group-labels">
                                    <div className="input-lay col-3">
                                        <div className="text">Type of project</div>
                                        <CustomSelect placeholder='Select' name='project_type'
                                                      selectChange={this.dropDownChange}
                                                      value={this.state.fields.project_type}
                                                      options={[
                                                          {
                                                              value: 'Homestay (for Airbnb rentals)',
                                                              text: 'Homestay (for Airbnb rentals)'
                                                          },
                                                          {
                                                              value: 'Villa or bungalow resort',
                                                              text: 'Villa or bungalow resort'
                                                          },
                                                          {value: 'Multi-story hotel', text: 'Multi-story hotel'},
                                                          {value: 'Other', text: 'Other'},
                                                      ]}
                                        />
                                        <div className="select-wrapp" onClick={() => {
                                            ModalView('contact/country-select');
                                            context.change_select_options([
                                                {text: 'Homestay (for Airbnb rentals)'},
                                                {text: 'Villa or bungalow resort'},
                                                {text: 'Multi-story hotel'},
                                                {text: 'Other'},
                                            ], 'project_type');
                                            context.toggle_popup('popup-select');
                                        }}/>
                                    </div>
                                    <div className="input-lay col-3">
                                        <div className="text">Rooms</div>
                                        <CustomSelect placeholder='Select' name='rooms'
                                                      selectChange={this.dropDownChange}
                                                      value={this.state.fields.rooms}
                                                      options={[
                                                          {value: '4 or less', text: '4 or less'},
                                                          {value: '5 - 10', text: '5 - 10'},
                                                          {value: '11 - 20', text: '11 - 20'},
                                                          {value: '21 - 50', text: '21 - 50'},
                                                          {value: 'Over 50', text: 'Over 50'},
                                                          {value: 'Not sure yet', text: 'Not sure yet'},
                                                      ]}
                                        />
                                        <div className="select-wrapp" onClick={() => {
                                            ModalView('contact/country-select');
                                            context.change_select_options([
                                                {text: '4 or less'},
                                                {text: '5 - 10'},
                                                {text: '11 - 20'},
                                                {text: '21 - 50'},
                                                {text: 'Over 50'},
                                                {text: 'Not sure yet'},
                                            ], 'rooms');
                                            context.toggle_popup('popup-select');
                                        }}/>
                                    </div>
                                    <div className="input-lay col-3">
                                        <div className="text">Financing</div>
                                        <CustomSelect placeholder='Select' name='financing'
                                                      selectChange={this.dropDownChange}
                                                      value={this.state.fields.financing}
                                                      options={[
                                                          {
                                                              value: 'Interested in financing',
                                                              text: 'Interested in financing'
                                                          },
                                                          {
                                                              value: 'Not interested in financing',
                                                              text: 'Not interested in financing'
                                                          },
                                                          {value: 'Not sure yet', text: 'Not sure yet'}
                                                      ]}
                                        />
                                        <div className="select-wrapp" onClick={() => {
                                            ModalView('contact/country-select');
                                            context.change_select_options([
                                                {text: 'Interested in financing'},
                                                {text: 'Not interested in financing'},
                                                {text: 'Not sure yet'}
                                            ], 'financing');
                                            context.toggle_popup('popup-select');
                                        }}/>
                                    </div>
                                    <div className="input-lay col-3">
                                        <div className="text">Land</div>
                                        <CustomSelect placeholder='Select' name='land'
                                                      selectChange={this.dropDownChange}
                                                      value={this.state.fields.land}
                                                      options={[
                                                          {
                                                              value: 'I own land plot for project',
                                                              text: 'I own land plot for project'
                                                          },
                                                          {
                                                              value: 'Land plot for project is rented',
                                                              text: 'Land plot for project is rented'
                                                          },
                                                          {
                                                              value: 'Land plot for project not secured yet',
                                                              text: 'Land plot for project not secured yet'
                                                          },
                                                          {value: 'Other', text: 'Other'}
                                                      ]}
                                        />
                                        <div className="select-wrapp" onClick={() => {
                                            ModalView('contact/country-select');
                                            context.change_select_options([
                                                {text: 'I own land plot for project'},
                                                {text: 'Land plot for project is rented'},
                                                {text: 'Land plot for project not secured yet'},
                                                {text: 'Other'}
                                            ], 'land');
                                            context.toggle_popup('popup-select');
                                        }}/>
                                    </div>
                                </div>
                                <div className="input-lay lay-textarea">
                                    <div className="text">Project description</div>
                                    <textarea name="description" placeholder="Enter your description"/>
                                </div>
                            </div>
                            <div className="partnership-block">
                                <div className="group-labels">
                                    <div className="input-lay col-6">
                                        <div className="text">Type of cooperation</div>
                                        <CustomSelect placeholder='Select' name='cooperation_type'
                                                      selectChange={this.dropDownChange}
                                                      value={this.state.fields.cooperation_type}
                                                      options={[
                                                          {
                                                              value: 'Regional representative',
                                                              text: 'Regional representative'
                                                          },
                                                          {
                                                              value: 'Apply for job with Residio.tech',
                                                              text: 'Apply for job with Residio.tech'
                                                          },
                                                          {value: 'Other', text: 'Other'}
                                                      ]}
                                        />
                                        <div className="select-wrapp" onClick={() => {
                                            ModalView('contact/country-select');
                                            context.change_select_options([
                                                {text: 'Regional representative'},
                                                {text: 'Apply for job with Residio.tech'},
                                                {text: 'Other'}
                                            ], 'cooperation_type');
                                            context.toggle_popup('popup-select');
                                        }}/>
                                    </div>
                                </div>
                                <div className="input-lay lay-textarea">
                                    <div className="text">Message</div>
                                    <textarea name="partnership_message" placeholder="Write your message"/>
                                </div>
                            </div>
                            <div className="forward-information-block">
                                <div className="group-labels">
                                    <div className="input-lay col-6">
                                        <div className="text">Email</div>
                                        <input name="forward_email" type="text" className="form-control input-my"
                                               placeholder="Email of person to receive Residio.tech info"/>
                                    </div>
                                    <div className="input-lay col-6">
                                        <div className="text">Subject</div>
                                        <input name="forward_subject" type="text" className="form-control input-my"
                                               placeholder="Hello, I believe you will like Residio.tech"/>
                                    </div>
                                </div>
                                <div className="input-lay lay-textarea">
                                    <div className="text">Message</div>
                                    <textarea name="forward_message" placeholder="Write your message"/>
                                </div>
                            </div>
                            <div className="inquiry-block">
                                <div className="input-lay lay-textarea">
                                    <div className="text">Message</div>
                                    <textarea name="other_message" placeholder="Write your message"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="controls">
                        <div className="input-lay col-6">
                            <div className="hidden_attachment attach_block">
                                <div className="text">Attachments</div>
                                <FileUpload onChange={this.handleFieldChange} ref={this.attachmentsElement}/>
                            </div>
                        </div>
                        <div className="input-lay col-6">
                            <div className="text"/>
                            <div className="page-btn">
                                <button type="submit" name="submit" className="btn btn-default contact">{this.state.button}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="groups">
                            <div className="line">© RESIDIO.TECH 2016 - 2019. All rights reserved</div>
                            <div className="line">
                                    <span id="privacy-info" onClick={() => {
                                        ModalView('contact/privacy_info')
                                        context.toggle_popup('contact_privacy_info');
                                    }}>Privacy statement</span> |
                                <span id="terms-of-use-info" onClick={() => {
                                    ModalView('contact/term')
                                    context.toggle_popup('contact_term');
                                }}>Terms of use</span> |
                                <span id="contact-info" onClick={() => {
                                    ModalView('contact/info')
                                    context.toggle_popup('contact_info');
                                }}>Contact</span>
                            </div>
                        </div>
                        <div className="language">
                            <img src={process.env.PUBLIC_URL + "/assets/img/contact/icon-in.png"} alt="#"/>
                            <span className="social">Linkedin</span>
                            <img src={process.env.PUBLIC_URL + "/assets/img/contact/icon-fb.png"} alt="#"/>
                            <span className="social">Facebook</span> |
                            <img className="language"
                                 src={process.env.PUBLIC_URL + "/assets/img/contact/planet.png"} alt="#"/>
                            <span>English</span>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

const ContactWithContext = () => {
    return <AppContext.Consumer>{(context) => {
        const {country_select} = context;
        return <Contact context={context} countrySelect={country_select}/>;
    }}</AppContext.Consumer>
};

export default ContactWithContext;
