import React from 'react';
import Button from "../../components/button/button";

class ProjectHomestayRental extends React.Component{
    render() {
        return (
            <div className="slide project-slide projects-homestay-rentals ">
                <div className="group hotel is-animated group-left project-info container">
                    <div className="cont">
                        <div className="drop">
                            <div className="box collapsed" data-toggle="collapse" href="#boxCollapse"
                                 aria-expanded="false" aria-controls="boxCollapse">
                                <div className="bg-spot"/>
                                <div className="text-line">HOMESTAY FOR AIRBNB (3 KEYS)</div>
                                <i className="fas fa-chevron-down"/>
                                <i className="fas fa-chevron-up"/>
                            </div>
                            <div className="box collapse" id="boxCollapse">
                                <div className="bg-spot"/>
                                <div className="text-small">
                                    Launch time: 6 months<br/>
                                    Cost per key: USD 52.500<sup>(1)</sup><br/>
                                    Operator investment: USD 84.000<sup>(2)</sup><br/>
                                    <span className="line-br"/> <br/>
                                    <span className="tip">(1): Excluding land; (2): With financing</span>
                                </div>
                            </div>
                        </div>
                        <div className="box">
                            <div className="bg-spot"/>
                            <div className="text-line">HOMESTAY FOR AIRBNB (3 KEYS)</div>
                        </div>
                        <div className="box">
                            <div className="bg-spot"/>
                            <div className="text-small">
                                Launch time: 6 months<br/>
                                Cost per key: USD 52.500<sup>(1)</sup><br/>
                                Operator investment: USD 84.000<sup>(2)</sup><br/>
                                <span className="line-br"/> <br/>
                                <span className="tip">(1): Excluding land; (2): With financing</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="group hotel is-animated group-line shoutout-block">
                    <div className="box">
                        <div className="all-cont">
                            <div className="text-high-slide">LOW COST PER KEY</div>
                            <div className="text-ital-slide">50% cost reduction vs traditional construction
                            </div>
                            <div className="bg-bottom-line-my">
                                <div className="time-line">
                                    <div className="step"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Button is_project={true} popup_name='project' project_name='homestay_rentals' text='learn more'
                        setPopupClass='project-wrapper popup-wrapper-active' />
            </div>

    );
    }
}

export default ProjectHomestayRental;
