import React from 'react';

const COUNTRIES =[
  { value: "+93", text:"Afghanistan",  markup:<div><span>+93</span>Afghanistan</div>},
  { value: "+355", text:"Albania",  markup: <div><span>+355</span>Albania</div>},
  { value: "+21", text:"Algeria",  markup: <div><span>+21</span>Algeria</div>},
  { value: "+684", text:"American Samoa",  markup: <div><span>+684</span>American Samoa</div>},
  { value: "+376", text:"Andorra",  markup: <div><span>+376</span>Andorra</div>},
  { value: "+1-268", text:"Antigua and Barbuda",  markup: <div><span>+1-268</span>Antigua and Barbuda</div>},
  { value: "+374", text:"Armenia",  markup: <div><span>+374</span>Armenia</div>},
  { value: "+54", text:"Argentina",  markup: <div><span>+54</span>Argentina</div>},
  { value: "+61", text:"Australia",  markup: <div><span>+61</span>Australia</div>},
  { value: "+43", text:"Austria",  markup: <div><span>+43</span>Austria</div>},
  { value: "+994", text:"Azerbaijan",  markup: <div><span>+994</span>Azerbaijan</div>},
  { value: "+1-242", text:"Bahamas",  markup: <div><span>+1-242</span>Bahamas</div>},
  { value: "+973", text:"Bahrain",  markup: <div><span>+973</span>Bahrain</div>},
  { value: "+880", text:"Bangladesh",  markup: <div><span>+880</span>Bangladesh</div>},
  { value: "+1-246", text:"Barbados",  markup: <div><span>+1-246</span>Barbados</div>},
  { value: "+375", text:"Belarus",  markup: <div><span>+375</span>Belarus</div>},
  { value: "+32", text:"Belgium",  markup: <div><span>+32</span>Belgium</div>},
  { value: "+501", text:"Belize",  markup: <div><span>+501</span>Belize</div>},
  { value: "+1-441", text:"Bermuda",  markup: <div><span>+1-441</span>Bermuda</div>},
  { value: "+591", text:"Bolivia",  markup: <div><span>+591</span>Bolivia</div>},
  { value: "+387", text:"Bosnia and Herzegovina",  markup: <div><span>+387</span>Bosnia and Herzegovina</div>},
  { value: "+267", text:"Botswana",  markup: <div><span>+267</span>Botswana</div>},
  { value: "+55", text:"Brazil",  markup: <div><span>+55</span>Brazil</div>},
  { value: "+1-284", text:"British Virgin Islands",  markup: <div><span>+1-284</span>British Virgin Islands</div>},
  { value: "+673", text:"Brunei Darusalaam",  markup: <div><span>+673</span>Brunei Darusalaam</div>},
  { value: "+359", text:"Bulgaria",  markup: <div><span>+359</span>Bulgaria</div>},
  { value: "+257", text:"Burundi",  markup: <div><span>+257</span>Burundi</div>},
  { value: "+855", text:"Cambodia",  markup: <div><span>+855</span>Cambodia</div>},
  { value: "+237", text:"Cameroon",  markup: <div><span>+237</span>Cameroon</div>},
  { value: "+1", text:"Canada",  markup: <div><span>+1</span>Canada</div>},
  { value: "+238", text:"Cape Verde",  markup: <div><span>+238</span>Cape Verde</div>},
  { value: "+1-345", text:"Cayman Islands",  markup: <div><span>+1-345</span>Cayman Islands</div>},
  { value: "+236", text:"Central African Republic",  markup: <div><span>+236</span>Central African Republic</div>},
  { value: "+235", text:"Chad",  markup: <div><span>+235</span>Chad</div>},
  { value: "+56", text:"Chile",  markup: <div><span>+56</span>Chile</div>},
  { value: "+672", text:"Christmas Island",  markup: <div><span>+672</span>Christmas Island</div>},
  { value: "+672", text:"Cocos Islands",  markup: <div><span>+672</span>Cocos Islands</div>},
  { value: "+57", text:"Colombia",  markup: <div><span>+57</span>Colombia</div>},
  { value: "+1-670", text:"Commonwealth of the Northern Mariana Islands",  markup: <div><span>+1-670</span>Commonwealth of the Northern Mariana Islands</div>},
  { value: "+269", text:"Comoros and Mayotte Island",  markup: <div><span>+269</span>Comoros and Mayotte Island</div>},
  { value: "+242", text:"Congo",  markup: <div><span>+242</span>Congo</div>},
  { value: "+682", text:"Cook Islands",  markup: <div><span>+682</span>Cook Islands</div>},
  { value: "+682", text:"Cook Islands",  markup: <div><span>+682</span>Cook Islands</div>},
  { value: "+506", text:"Costa Rica",  markup: <div><span>+506</span>Costa Rica</div>},
  { value: "+385", text:"Croatia",  markup: <div><span>+385</span>Croatia</div>},
  { value: "+53", text:"Cuba",  markup: <div><span>+53</span>Cuba</div>},
  { value: "+357", text:"Cyprus",  markup: <div><span>+357</span>Cyprus</div>},
  { value: "+420", text:"Czech Republic",  markup: <div><span>+420</span>Czech Republic</div>},
  { value: "+45", text:"Denmark",  markup: <div><span>+45</span>Denmark</div>},
  { value: "+253", text:"Djibouti",  markup: <div><span>+253</span>Djibouti</div>},
  { value: "+1-767", text:"Dominica",  markup: <div><span>+1-767</span>Dominica</div>},
  { value: "+1-809", text:"Dominican Republic",  markup: <div><span>+1-809</span>Dominican Republic</div>},
  { value: "+62", text:"East Timor",  markup: <div><span>+62</span>East Timor</div>},
  { value: "+593", text:"Ecuador",  markup: <div><span>+593</span>Ecuador</div>},
  { value: "+20", text:"Egypt",  markup: <div><span>+20</span>Egypt</div>},
  { value: "+503", text:"El Salvador",  markup: <div><span>+503</span>El Salvador</div>},
  { value: "+240", text:"Equatorial Guinea",  markup: <div><span>+240</span>Equatorial Guinea</div>},
  { value: "+372", text:"Estonia",  markup: <div><span>+372</span>Estonia</div>},
  { value: "+251", text:"Ethiopia",  markup: <div><span>+251</span>Ethiopia</div>},
  { value: "+2512", text:"Faeroe Islands",  markup: <div><span>+2512</span>Faeroe Islands</div>},
  { value: "+500", text:"Falkland Islands",  markup: <div><span>+500</span>Falkland Islands</div>},
  { value: "+679", text:"Fiji",  markup: <div><span>+679</span>Fiji</div>},
  { value: "+679", text:"Fiji",  markup: <div><span>+679</span>Fiji</div>},
  { value: "+358", text:"Finland",  markup: <div><span>+358</span>Finland</div>},
  { value: "+33", text:"France",  markup: <div><span>+33</span>France</div>},
  { value: "+590", text:"French Antilles",  markup: <div><span>+590</span>French Antilles</div>},
  { value: "+594", text:"French Guiana",  markup: <div><span>+594</span>French Guiana</div>},
  { value: "+689", text:"French Polynesia",  markup: <div><span>+689</span>French Polynesia</div>},
  { value: "+241", text:"Gabon",  markup: <div><span>+241</span>Gabon</div>},
  { value: "+220", text:"Gambia",  markup: <div><span>+220</span>Gambia</div>},
  { value: "+995", text:"Georgia",  markup: <div><span>+995</span>Georgia</div>},
  { value: "+49", text:"Germany",  markup: <div><span>+49</span>Germany</div>},
  { value: "+233", text:"Ghana",  markup: <div><span>+233</span>Ghana</div>},
  { value: "+350", text:"Gibraltar",  markup: <div><span>+350</span>Gibraltar</div>},
  { value: "+30", text:"Greece",  markup: <div><span>+30</span>Greece</div>},
  { value: "+299", text:"Greenland",  markup: <div><span>+299</span>Greenland</div>},
  { value: "+1-473", text:"Grenada",  markup: <div><span>+1-473</span>Grenada</div>},
  { value: "+1-671", text:"Guam",  markup: <div><span>+1-671</span>Guam</div>},
  { value: "+502", text:"Guatemala",  markup: <div><span>+502</span>Guatemala</div>},
  { value: "+224", text:"Guinea",  markup: <div><span>+224</span>Guinea</div>},
  { value: "+245", text:"Guinea-Bissau",  markup: <div><span>+245</span>Guinea-Bissau</div>},
  { value: "+592", text:"Guyana",  markup: <div><span>+592</span>Guyana</div>},
  { value: "+509", text:"Haiti",  markup: <div><span>+509</span>Haiti</div>},
  { value: "+504", text:"Honduras",  markup: <div><span>+504</span>Honduras</div>},
  { value: "+852", text:"Hong Kong",  markup: <div><span>+852</span>Hong Kong</div>},
  { value: "+36", text:"Hungary",  markup: <div><span>+36</span>Hungary</div>},
  { value: "+354", text:"Iceland",  markup: <div><span>+354</span>Iceland</div>},
  { value: "+91", text:"India",  markup: <div><span>+91</span>India</div>},
  { value: "+62", text:"Indonesia",  markup: <div><span>+62</span>Indonesia</div>},
  { value: "+98", text:"Iran",  markup: <div><span>+98</span>Iran</div>},
  { value: "+964", text:"Iraq",  markup: <div><span>+964</span>Iraq</div>},
  { value: "+353", text:"Irish Republic",  markup: <div><span>+353</span>Irish Republic</div>},
  { value: "+972", text:"Israel",  markup: <div><span>+972</span>Israel</div>},
  { value: "+39", text:"Italy",  markup: <div><span>+39</span>Italy</div>},
  { value: "+39", text:"Italy",  markup: <div><span>+39</span>Italy</div>},
  { value: "+225", text:"Ivory Coast",  markup: <div><span>+225</span>Ivory Coast</div>},
  { value: "+1-876", text:"Jamaica",  markup: <div><span>+1-876</span>Jamaica</div>},
  { value: "+81", text:"Japan",  markup: <div><span>+81</span>Japan</div>},
  { value: "+962", text:"Jordan",  markup: <div><span>+962</span>Jordan</div>},
  { value: "+7", text:"Kazakhstan",  markup: <div><span>+7</span>Kazakhstan</div>},
  { value: "+254", text:"Kenya",  markup: <div><span>+254</span>Kenya</div>},
  { value: "+686", text:"Kiribati Republic",  markup: <div><span>+686</span>Kiribati Republic</div>},
  { value: "+996", text:"Kirghizia",  markup: <div><span>+996</span>Kirghizia</div>},
  { value: "+965", text:"Kuwait",  markup: <div><span>+965</span>Kuwait</div>},
  { value: "+856", text:"Laos",  markup: <div><span>+856</span>Laos</div>},
  { value: "+371", text:"Latvia",  markup: <div><span>+371</span>Latvia</div>},
  { value: "+961", text:"Lebanon",  markup: <div><span>+961</span>Lebanon</div>},
  { value: "+231", text:"Liberia",  markup: <div><span>+231</span>Liberia</div>},
  { value: "+21", text:"Libya",  markup: <div><span>+21</span>Libya</div>},
  { value: "+41", text:"Liechtenstein",  markup: <div><span>+41</span>Liechtenstein</div>},
  { value: "+370", text:"Lithuania",  markup: <div><span>+370</span>Lithuania</div>},
  { value: "+352", text:"Luxembourg",  markup: <div><span>+352</span>Luxembourg</div>},
  { value: "+853", text:"Macao",  markup: <div><span>+853</span>Macao</div>},
  { value: "+389", text:"Macedonia",  markup: <div><span>+389</span>Macedonia</div>},
  { value: "+261", text:"Madagascar",  markup: <div><span>+261</span>Madagascar</div>},
  { value: "+265", text:"Malawi",  markup: <div><span>+265</span>Malawi</div>},
  { value: "+60", text:"Malaysia",  markup: <div><span>+60</span>Malaysia</div>},
  { value: "+960", text:"Maldives",  markup: <div><span>+960</span>Maldives</div>},
  { value: "+223", text:"Mali",  markup: <div><span>+223</span>Mali</div>},
  { value: "+356", text:"Malta",  markup: <div><span>+356</span>Malta</div>},
  { value: "+692", text:"Marshall Islands",  markup: <div><span>+692</span>Marshall Islands</div>},
  { value: "+596", text:"Martinique",  markup: <div><span>+596</span>Martinique</div>},
  { value: "+222", text:"Mauritania",  markup: <div><span>+222</span>Mauritania</div>},
  { value: "+230", text:"Mauritius",  markup: <div><span>+230</span>Mauritius</div>},
  { value: "+1-706, +1-905, +52", text:"Mexico",  markup: <div><span>+1-706, +1-905, +52</span>Mexico</div>},
  { value: "+691", text:"Micronesia",  markup: <div><span>+691</span>Micronesia</div>},
  { value: "+377", text:"Monaco",  markup: <div><span>+377</span>Monaco</div>},
  { value: "+377", text:"Mongolia",  markup: <div><span>+377</span>Mongolia</div>},
  { value: "+1-664", text:"Montserrat",  markup: <div><span>+1-664</span>Montserrat</div>},
  { value: "+212", text:"Morocco",  markup: <div><span>+212</span>Morocco</div>},
  { value: "+258", text:"Mozambique",  markup: <div><span>+258</span>Mozambique</div>},
  { value: "+95", text:"Myanmar",  markup: <div><span>+95</span>Myanmar</div>},
  { value: "+264", text:"Namibia",  markup: <div><span>+264</span>Namibia</div>},
  { value: "+674", text:"Nauru",  markup: <div><span>+674</span>Nauru</div>},
  { value: "+977", text:"Nepal",  markup: <div><span>+977</span>Nepal</div>},
  { value: "+31", text:"Netherlands",  markup: <div><span>+31</span>Netherlands</div>},
  { value: "+599", text:"Netherlands Antilles",  markup: <div><span>+599</span>Netherlands Antilles</div>},
  { value: "+687", text:"New Caledonia",  markup: <div><span>+687</span>New Caledonia</div>},
  { value: "+64", text:"New Zealand",  markup: <div><span>+64</span>New Zealand</div>},
  { value: "+505", text:"Nicaragua",  markup: <div><span>+505</span>Nicaragua</div>},
  { value: "+227", text:"Niger",  markup: <div><span>+227</span>Niger</div>},
  { value: "+234", text:"Nigeria",  markup: <div><span>+234</span>Nigeria</div>},
  { value: "+672", text:"Niue",  markup: <div><span>+672</span>Niue</div>},
  { value: "+672", text:"Norfolk Island",  markup: <div><span>+672</span>Norfolk Island</div>},
  { value: "+850", text:"North Korea",  markup: <div><span>+850</span>North Korea</div>},
  { value: "+967", text:"North Yemen",  markup: <div><span>+967</span>North Yemen</div>},
  { value: "+670", text:"Northern Mariana Islands",  markup: <div><span>+670</span>Northern Mariana Islands</div>},
  { value: "+47", text:"Norway",  markup: <div><span>+47</span>Norway</div>},
  { value: "+968", text:"Oman",  markup: <div><span>+968</span>Oman</div>},
  { value: "+92", text:"Pakistan",  markup: <div><span>+92</span>Pakistan</div>},
  { value: "+507", text:"Panama",  markup: <div><span>+507</span>Panama</div>},
  { value: "+595", text:"Paraguay",  markup: <div><span>+595</span>Paraguay</div>},
  { value: "+51", text:"Peru",  markup: <div><span>+51</span>Peru</div>},
  { value: "+63", text:"Philippines",  markup: <div><span>+63</span>Philippines</div>},
  { value: "+48", text:"Poland",  markup: <div><span>+48</span>Poland</div>},
  { value: "+351", text:"Portugal",  markup: <div><span>+351</span>Portugal</div>},
  { value: "+1-787", text:"Puerto Rico",  markup: <div><span>+1-787</span>Puerto Rico</div>},
  { value: "+974", text:"Qatar",  markup: <div><span>+974</span>Qatar</div>},
  { value: "+378", text:"Republic of San Marino",  markup: <div><span>+378</span>Republic of San Marino</div>},
  { value: "+262", text:"Reunion",  markup: <div><span>+262</span>Reunion</div>},
  { value: "+40", text:"Romania",  markup: <div><span>+40</span>Romania</div>},
  { value: "+7", text:"Russia",  markup: <div><span>+7</span>Russia</div>},
  { value: "+250", text:"Rwandese Republic",  markup: <div><span>+250</span>Rwandese Republic</div>},
  { value: "+247", text:"Saint Helena and Ascension Island",  markup: <div><span>+247</span>Saint Helena and Ascension Island</div>},
  { value: "+247", text: "Saint Helena and Ascension Island",  markup: <div><span>+247</span>Saint Helena and Ascension Island</div>},
  { value: "+508", text: "Saint Pierre et Miquelon",  markup: <div><span>+508</span>Saint Pierre et Miquelon</div>},
  { value: "+39", text: "San Marino",  markup: <div><span>+39</span>San Marino</div>},
  { value: " ",text: "Sao Tome e Principe",  markup: <div><span/>Sao Tome e Principe</div>},
  { value: "+966", text: "Saudi Arabia",  markup: <div><span>+966</span>Saudi Arabia</div>},
  { value: "+221", text: "Senegal",  markup: <div><span>+221</span>Senegal</div>},
  { value: "", text: "Seychelles",  markup: <div><span>+</span>Seychelles</div>},
  { value: "+232", text: "Sierra Leone",  markup: <div><span>+232</span>Sierra Leone</div>},
  { value: "+65", text: "Singapore",  markup: <div><span>+65</span>Singapore</div>},
  { value: "+421", text: "Slovakia",  markup: <div><span>+421</span>Slovakia</div>},
  { value: "+386", text: "Slovenia",  markup: <div><span>+386</span>Slovenia</div>},
  { value: "+677", text: "Solomon Islands",  markup: <div><span>+677</span>Solomon Islands</div>},
  { value: "+252", text: "Somalia",  markup: <div><span>+252</span>Somalia</div>},
  { value: "+27", text: "South Africa",  markup: <div><span>+27</span>South Africa</div>},
  { value: "+82", text: "South Korea",  markup: <div><span>+82</span>South Korea</div>},
  { value: "+969", text: "South Yemen",  markup: <div><span>+969</span>South Yemen</div>},
  { value: "+34", text: "Spain",  markup: <div><span>+34</span>Spain</div>},
  { value: "+94", text: "Sri Lanka",  markup: <div><span>+94</span>Sri Lanka</div>},
  { value: "+1-869", text: "St.Kitts and Nevis",  markup: <div><span>+1-869</span>St.Kitts and Nevis</div>},
  { value: "+1-758", text: "St.Lucia",  markup: <div><span>+1-758</span>St.Lucia</div>},
  { value: "+1-784", text: "St.Vincent and the Grenadines",  markup: <div><span>+1-784</span>St.Vincent and the Grenadines</div>},
  { value: "+249", text: "Sudan",  markup: <div><span>+249</span>Sudan</div>},
  { value: "+597", text: "Suriname",  markup: <div><span>+597</span>Suriname</div>},
  { value: "",text: "Svalbard and Jan Mayen Islands",  markup: <div><span>+</span>Svalbard and Jan Mayen Islands</div>},
  { value: "+268", text: "Swaziland",  markup: <div><span>+268</span>Swaziland</div>},
  { value: "+46", text: "Sweden",  markup: <div><span>+46</span>Sweden</div>},
  { value: "+41", text: "Switzerland",  markup: <div><span>+41</span>Switzerland</div>},
  { value: "+963", text: "Syria",  markup: <div><span>+963</span>Syria</div>},
  { value: "+992", text: "Tajikistan",  markup: <div><span>+992</span>Tajikistan</div>},
  { value: "+886", text: "Taiwan",  markup: <div><span>+886</span>Taiwan</div>},
  { value: "+255", text: "Tanzania",  markup: <div><span>+255</span>Tanzania</div>},
  { value: "+66", text: "Thailand",  markup: <div><span>+66</span>Thailand</div>},
  { value: "+228", text: "Togolese Republic",  markup: <div><span>+228</span>Togolese Republic</div>},
  { value: "" ,text: "Tokelau",  markup: <div><span>+</span>Tokelau</div>},
  { value: "+676", text: "Tonga",  markup: <div><span>+676</span>Tonga</div>},
  { value: "+1-868", text: "Trinidad and Tobago",  markup: <div><span>+1-868</span>Trinidad and Tobago</div>},
  { value: "+21", text: "Tunisia",  markup: <div><span>+21</span>Tunisia</div>},
  { value: "+90", text: "Turkey",  markup: <div><span>+90</span>Turkey</div>},
  { value: "+993", text: "Turkmenistan",  markup: <div><span>+993</span>Turkmenistan</div>},
  { value: "+1-649", text: "Turks & Caicos Islands",  markup: <div><span>+1-649</span>Turks & Caicos Islands</div>},
  { value: "+688", text: "Tuvalu",  markup: <div><span>+688</span>Tuvalu</div>},
  { value: "+1-340", text: "US Virgin Islands",  markup: <div><span>+1-340</span>US Virgin Islands</div>},
  { value: "+256", text: "Uganda",  markup: <div><span>+256</span>Uganda</div>},
  { value: "+380", text: "Ukraine",  markup: <div><span>+380</span>Ukraine</div>},
  { value: "+971", text: "United Arab Emirates",  markup: <div><span>+971</span>United Arab Emirates</div>},
  { value: "+44", text: "United Kingdom",  markup: <div><span>+44</span>United Kingdom</div>},
  { value: "+598", text: "Uruguay",  markup: <div><span>+598</span>Uruguay</div>},
  { value: "+1", text: "USA",  markup: <div><span>+1</span>USA</div>},
  { value: "+998", text: "Uzbekistan",  markup: <div><span>+998</span>Uzbekistan</div>},
  { value: "+39", text: "Vatican City",  markup: <div><span>+39</span>Vatican City</div>},
  { value: "+58", text: "Venezuela",  markup: <div><span>+58</span>Venezuela</div>},
  { value: "+84", text: "Vietnam",  markup: <div><span>+84</span>Vietnam</div>},
  { value: "+21", text: "Western Sahara",  markup: <div><span>+21</span>Western Sahara</div>},
  { value: "+685", text: "Western Samoa",  markup: <div><span>+685</span>Western Samoa</div>},
  { value: "+381", text: "Yugoslavia",  markup: <div><span>+381</span>Yugoslavia</div>},
  { value: "+243", text: "Zaire",  markup: <div><span>+243</span>Zaire</div>},
  { value: "+260", text: "Zambia",  markup: <div><span>+260</span>Zambia</div>},
  { value: "+263", text: "Zimbabwe",  markup: <div><span>+263</span>Zimbabwe</div>}
];
export default COUNTRIES;
