import React from 'react';
import './progect.scss';
import ProjectBungalow from './projects_bungalow_resort';
import ProjectHomestayRental from './projects_homestay_rentals';
import ProjectMultistoryHotel from "./projects_multistory_hotel";
import ProjectTropicalResort from "./projects_tropical_resort";

class Project extends React.Component{

    render() {
        return (
            <div>
                <ProjectHomestayRental/>
                <ProjectBungalow/>
                <ProjectMultistoryHotel/>
                <ProjectTropicalResort/>
            </div>
        );
    }
}

export default Project;
