import React from 'react';
import './solution.scss';
import Slider from "react-slick";
import PopupBackLine from '../popup-back-line';
import PopupCloseIcon from '../popup-close-icon';

class SolutionPopup extends React.Component{
    componentDidMount() {
        this.props.popupModyfy();
        this.props.whellScroll(this.slider);
    };

    render() {
        const settings = this.props.settings;
        return (
            <div className='container'>
                <div className="elements">
                    <Slider ref={c => (this.slider = c)} {...settings} className='slick-slider'>
                        <div className="p-slide">
                            <PopupBackLine page="solutions" />
                            <div className="content">
                                <div className="head-text">Rigorous R&D focused on cost reduction combined with
                                    centralized production makes Residio.tech units more cost efficient than other
                                    solutions available on the market today​
                                </div>
                                <div className="photo"/>
                            </div>
                        </div>

                        <div className="p-slide">
                            <PopupBackLine page="solutions" />
                            <div className="content">
                                <div className="head-text">Residio.tech has full range of products for your homestay,
                                    resort or hotel project​​
                                </div>
                                <div className="items">
                                    <div className="item">
                                        <div className="item-head">
                                            Residential 20’; 30’; 40’ Units<br />
                                            <small>(single and multi bedroom)</small>
                                        </div>
                                        <div className="item-body">
                                            <div className="block"><img src={process.env.PUBLIC_URL +"/assets/img/popup/solution/photo-1.png"}
                                                                        alt="#"/></div>
                                            <div className="block"><img src={process.env.PUBLIC_URL +"/assets/img/popup/solution/photo-2.png"}
                                                                        alt="#"/></div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-head">
                                            Public areas and technical<br />
                                            <small>(reception, storage, bar)​</small>
                                        </div>
                                        <div className="item-body">
                                            <div className="block"><img src={process.env.PUBLIC_URL +"/assets/img/popup/solution/photo-3.png"}
                                                                        alt="#"/></div>
                                            <div className="block"><img src={process.env.PUBLIC_URL +"/assets/img/popup/solution/photo-4.png"}
                                                                        alt="#"/></div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="item-head">
                                            Technical <small>(portable pools,<br />
                                            water treatment & storage)​</small>
                                        </div>
                                        <div className="item-body">
                                            <div className="block"><img src={process.env.PUBLIC_URL +"/assets/img/popup/solution/photo-5.png"}
                                                                        alt="#"/></div>
                                            <div className="block"><img src={process.env.PUBLIC_URL +"/assets/img/popup/solution/photo-6.png"}
                                                                        alt="#"/></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                    <PopupCloseIcon/>
                </div>
            </div>
        );
    }
}
export default SolutionPopup;
