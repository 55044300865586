import React from 'react';
import './radio-check-input.scss';

class RadioInputCheck extends React.Component{
    render() {
        return (
            <div className={'custom-radios'}>
                <div>
                    <input className='custom-c' type="radio" id={this.props.id} name={this.props.name} value={this.props.text}  />
                    <label htmlFor={this.props.id}>
                        <span/>
                        <div className="c-text">{this.props.text}</div>
                    </label>
                </div>
            </div>
        );
    }
}

export default RadioInputCheck;
