import React from 'react';
import './franchising.scss';
import Slider from "react-slick";
import PopupBackLine from '../popup-back-line';
import PopupCloseIcon from '../popup-close-icon';

class FranchisingPopup extends React.Component{
    componentDidMount() {
        this.props.popupModyfy();
        this.props.whellScroll(this.slider);
    };

    render() {
        const settings = this.props.settings;
        return (
            <div className='container'>
                <div className="elements">
                    <Slider ref={c => (this.slider = c)}  {...settings} className='slick-slider'>
                        <div className="p-slide franchasing-slide">
                            <PopupBackLine page="franchising" />
                            <div className="content">
                                <div className="head-text">Residio.tech franchisees come from all walks of life …. when will
                                    you become our partner?​
                                </div>
                                <div className="block-list">
                                    <div className="item">
                                        <div className="elm">
                                            <div className="logo-line">
                                                <img src={process.env.PUBLIC_URL +"/assets/img/popup/franchising/logo.png"} alt="#"/>
                                                <div className="p-data">
                                                    <div className="name">Tuan</div>
                                                    <div className="location">Phu Quoc, Vietnam</div>
                                                </div>
                                                <img className='flag-img' src={process.env.PUBLIC_URL +"/assets/img/popup/franchising/flag.png"}
                                                     alt="#"/>
                                            </div>
                                            <div className="text-items">
                                                <p>Owns several plots of land on Phu Quoc island</p>
                                                <p>Interested in developing resort, but does not have enough funds and time
                                                    to devote to developing and perfecting the concept</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="elm">
                                            <div className="logo-line">
                                                <img src={process.env.PUBLIC_URL +"/assets/img/popup/franchising/logo2.png"} alt="#"/>
                                                <div className="p-data">
                                                    <div className="name">Nelly</div>
                                                    <div className="location">NSW, Australia</div>
                                                </div>
                                                <img className='flag-img' src={process.env.PUBLIC_URL +"/assets/img/popup/franchising/flag2.png"}
                                                     alt="#"/>
                                            </div>
                                            <div className="text-items">
                                                <p>Owns farm land in Cox Creek in Blue Mountains</p>
                                                <p>Interested in developing yoga retreat on his land with homestay
                                                    accommodation</p>
                                                <p>Concerned about high construction cost in remote parts of Australia​</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="elm">
                                            <div className="logo-line">
                                                <img src={process.env.PUBLIC_URL +"/assets/img/popup/franchising/logo3.png"} alt="#"/>
                                                <div className="p-data">
                                                    <div className="name">Jirka</div>
                                                    <div className="location">Bali, Indonesia</div>
                                                </div>
                                                <img className='flag-img' src={process.env.PUBLIC_URL +"/assets/img/popup/franchising/flag3.png"}
                                                     alt="#"/>
                                            </div>
                                            <div className="text-items">
                                                <p>Successfully operates bakery and restaurant in Bali​</p>
                                                <p>Has opportunity to rent suitable land for 20 years​</p>
                                                <p>Lacks funds for development​​</p>
                                                <p>Wants proven concept to launch fast</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="elm">
                                            <div className="logo-line">
                                                <img src={process.env.PUBLIC_URL +"/assets/img/popup/franchising/logo4.png"} alt="#"/>
                                                <div className="p-data">
                                                    <div className="name">Surawong</div>
                                                    <div className="location">Ayutthaya, Thailand​</div>
                                                </div>
                                                <img className='flag-img' src={"/assets/img/popup/franchising/flag4.png"}
                                                     alt="#"/>
                                            </div>
                                            <div className="text-items">
                                                <p>Owns land among rice farms in Ayuthaya</p>
                                                <p>Interested in developing farm-holiday with homestay</p>
                                                <p>Has no experience in construction and very limited hospitality know
                                                    how</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="elm">
                                            <div className="logo-line">
                                                <img src={process.env.PUBLIC_URL +"/assets/img/popup/franchising/logo5.png"} alt="#"/>
                                                <div className="p-data">
                                                    <div className="name">Martin</div>
                                                    <div className="location">Great Exuma, Bahamas​</div>
                                                </div>
                                                <img className='flag-img' src={process.env.PUBLIC_URL +"/assets/img/popup/franchising/flag5.png"}
                                                     alt="#"/>
                                            </div>
                                            <div className="text-items">
                                                <p>Operates small hostel on a large plot of land</p>
                                                <p>Interested in expanding accommodation capacities, but lacks financial
                                                    resources and has little experience with construction</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="elm">
                                            <div className="logo-line">
                                                <img src={process.env.PUBLIC_URL +"/assets/img/popup/franchising/logo6.png"} alt="#"/>
                                                <div className="p-data">
                                                    <div className="name">Hongorsul</div>
                                                    <div className="location">Ulaanbaatar, Mongolia​</div>
                                                </div>
                                                <img className='flag-img' src={process.env.PUBLIC_URL +"/assets/img/popup/franchising/flag6.png"}
                                                     alt="#"/>
                                            </div>
                                            <div className="text-items">
                                                <p>Owns several acres of land out of Ulaanbaatar</p>
                                                <p>Interested in building a dirt bike touring base</p>
                                                <p>Afraid of issues related to construction planning, cost and construction
                                                    mgmt.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-slide franchasing-slide">
                            <PopupBackLine page="franchising" />
                            <div className="content-upfrond">
                                <div className="head-text">Residio.tech franchising model with low barriers of entry
                                    is suitable for family operated businesses
                                </div>
                                <div className="list-items">
                                    <div className="item">
                                        <div className="big-number">01</div>
                                        <div className="texts">
                                            <div className="title">Upfront fee</div>
                                            <div className="list">
                                                <div className="line">Initial project assessment​</div>
                                                <div className="line">Consultation and assistance in development master
                                                    plan​
                                                </div>
                                                <div className="line">Training in servicing concept development​</div>
                                            </div>
                                        </div>
                                        <div className="white-text">
                                            <div className="line">USD 10.000 for small project of 5 or less residential
                                                units​
                                            </div>
                                            <div className="line">USD 25.000 for project with more than 5 residential
                                                units
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="big-number">02</div>
                                        <div className="texts">
                                            <div className="title">Residio.tech technology delivery</div>
                                            <div className="list">
                                                <div className="line">According to Residio.tech price list and payment
                                                    calendar​
                                                </div>
                                            </div>
                                        </div>
                                        <div className="white-text">
                                            <div className="line">Price of fully equipped and furnished 20’ residential unit
                                                starts at​ $39.500 including worldwide delivery*
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="big-number">03</div>
                                        <div className="texts">
                                            <div className="title">Leasing of Residio.tech technology</div>
                                            <div className="list">
                                                <div className="line">Financing offered for up to 60% of value of
                                                    Residio.tech mobile technologies
                                                </div>
                                            </div>
                                        </div>
                                        <div className="white-text">
                                            <div className="line">up to 15 years with 5.99 APR**</div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="big-number">04</div>
                                        <div className="texts">
                                            <div className="title">Royalty payments</div>
                                            <div className="list">
                                                <div className="line">Reservations and channel management, customer
                                                    service​
                                                </div>
                                                <div className="line">Operational model blueprints and training</div>
                                                <div className="line">IT system development and support​</div>
                                            </div>
                                        </div>
                                        <div className="white-text">
                                            <br/>
                                            <div className="line">5.5% of gross revenue​</div>
                                            <br/>
                                            <div className="line"><i>Note: is charged only when minimum level of revenue is
                                                achieved</i>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="slider-footer">
                                        *: price depends on final specification and selected options; exceptions apply​ <br/>
                                        **: after risk assessment and based on approval of partnering financing partner
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-slide franchasing-slide hospital-slider">
                            <PopupBackLine page="franchising" />
                            <div className="content-hospitality">
                                <div className="head-text">Residio.tech hospitality concept offers attractive return on
                                    investment for franchisees​​
                                </div>
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th scope="col"/>
                                        <th scope="col">
                                            <div>Homestay
                                                <small>3 unit</small></div>
                                        </th>
                                        <th scope="col">
                                            <div>Bungalow resort 3*
                                                <small>12 unit</small></div>
                                        </th>
                                        <th scope="col">
                                            <div>Multy-story hotel
                                                <small>8 unit</small></div>
                                        </th>
                                        <th scope="col">
                                            <div>Tropical resort 4*
                                                <small>18 unit</small></div>
                                        </th>
                                    </tr>
                                    <tr>
                                        <th scope="col"/>
                                        <th scope="col"><img src={process.env.PUBLIC_URL +"/assets/img/popup/franchising/small-photo.png"} alt="#"/>
                                        </th>
                                        <th scope="col"><img src={process.env.PUBLIC_URL +"/assets/img/popup/franchising/small-photo2.png"} alt="#"/>
                                        </th>
                                        <th scope="col"><img src={process.env.PUBLIC_URL +"/assets/img/popup/franchising/small-photo3.png"} alt="#"/>
                                        </th>
                                        <th scope="col"><img src={process.env.PUBLIC_URL +"/assets/img/popup/franchising/small-photo4.png"} alt="#"/>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr className="t-title">
                                        <td>Project investment</td>
                                        <td/>
                                        <td/>
                                        <td/>
                                        <td/>
                                    </tr>
                                    <tr>
                                        <td>Retail price per unit, USD</td>
                                        <td>39 500</td>
                                        <td>39 500</td>
                                        <td>38 500</td>
                                        <td>39 900</td>
                                    </tr>
                                    <tr>
                                        <td>Total project investment (incl. site works)*, USD</td>
                                        <td>143 250</td>
                                        <td>658 600</td>
                                        <td>461 000</td>
                                        <td>1 331 200</td>
                                    </tr>
                                    <tr>
                                        <td>Residio.tech financing, USD</td>
                                        <td>59 250</td>
                                        <td>249 500</td>
                                        <td>166 500</td>
                                        <td>386 600</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Total franchisee equity, USD</strong></td>
                                        <td><strong>84 000</strong></td>
                                        <td><strong>409 100</strong></td>
                                        <td><strong>294 500</strong></td>
                                        <td><strong>944 600</strong></td>
                                    </tr>
                                    <tr className="t-title">
                                        <td>Operation</td>
                                        <td/>
                                        <td/>
                                        <td/>
                                        <td/>
                                    </tr>
                                    <tr>
                                        <td>Total revenue, USD</td>
                                        <td>35 099</td>
                                        <td>188 743</td>
                                        <td>136 369</td>
                                        <td>399 104</td>
                                    </tr>
                                    <tr>
                                        <td>Total costs, USD</td>
                                        <td>16 116</td>
                                        <td>96 477</td>
                                        <td>76 320</td>
                                        <td>178 382</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Profit before tax (EBTDA), USD</strong></td>
                                        <td><strong>18 983</strong></td>
                                        <td><strong>92 266</strong></td>
                                        <td><strong>60 049</strong></td>
                                        <td><strong>220 722</strong></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Investment yield (EBTDA), %</strong></td>
                                        <td><strong>22.60</strong></td>
                                        <td><strong>22.55</strong></td>
                                        <td><strong>20.39</strong></td>
                                        <td><strong>23.37</strong></td>
                                    </tr>
                                    <tr>
                                        <td><strong>Franchisee investment payback time, years</strong></td>
                                        <td><strong>4.4</strong></td>
                                        <td><strong>4.4</strong></td>
                                        <td><strong>4.9</strong></td>
                                        <td><strong>4.3</strong></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </Slider>
                    <PopupCloseIcon/>
                </div>
            </div>
        );
    }
}
export default FranchisingPopup;
