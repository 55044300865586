import React from 'react';
import './franchising.scss';
import Button from '../../components/button/button';

class Franchising extends React.Component{
    render() {
        return (

            <div className="container">
                <div className="f-title">Requirements for franchisees</div>
                <div className="f-text">Residio.tech offers unique opportunity to participate in its proven and
                    profitable hospitality concept. Selected partners are required to meet the following criteria
                </div>
                <div className="elements">
                    <div className="element">
                        <div className="box">
                            <img src={process.env.PUBLIC_URL +"/assets/img/franchising/icon.png"} alt="#"/>
                                <div className="b-title">Suitable land plot secured</div>
                                <div className="b-text">Secured by ownership or lease contract for a period of minimum 7
                                    years
                                </div>
                                <div className="list-t">Minimum plot sizes:</div>
                                <ul>
                                    <li>For homestay projects: 200 sqm</li>
                                    <li>For multi-story hotel: 450 sqm</li>
                                    <li>For tropical villa resort: 850 sqm</li>
                                </ul>
                        </div>
                    </div>
                    <div className="element">
                        <div className="box">
                            <img src={process.env.PUBLIC_URL +"/assets/img/franchising/icon2.png"} alt="#"/>
                                <div className="b-title">Own equity</div>
                                <div className="list-t">Required minimum equity for Residio.tech projects:
                                </div>
                                <ul>
                                    <li>For homestay projects: 75.000 USD</li>
                                    <li>For hotel or resort projects: 250.000 USD</li>
                                </ul>
                        </div>
                    </div>
                    <div className="element">
                        <div className="box">
                            <img src={process.env.PUBLIC_URL +"/assets/img/franchising/icon3.png"} alt="#"/>
                                <div className="b-title">Commitment</div>
                                <div className="b-text">We require that our partners are actively involved in managing
                                    of Residio.tech hospitality projects.
                                </div>
                                <div className="b-text">Franchisee or a person appointed by franchisee is required to
                                    commit at least 10 hours per week to developing, managing and optimizing
                                    Residio.tech franchise.
                                </div>
                        </div>
                    </div>
                </div>
                <Button popup_name='franchising' text='learn more' setPopupClass='franchasing-popup franchasing-popup-active' />
            </div>
        );
    }
}

export default Franchising;
