import React from 'react';
import './info.scss';
import PopupBackLine from '../../popup-back-line';
import PopupCloseIcon from '../../popup-close-icon';

class ContactInfoPopup extends React.Component{

    render() {
        return (
            <div className="contact-info-popup contact-info-popup-open">
                <div className="popup-lay">
                    <div className="content">
                        <div className="content-block">
                            <div className="head-text">Contact</div>
                            <div className="body-text">Residio.tech is trading name of Open Keys Ltd.;<br/>
                                239/160 ifield Bangna Village, Kanchanapisek Rd., Dokmai, Prawet,<br/>
                                Bangkok (10250), Thailand
                            </div>
                            <div className="body-text"><strong>Email:</strong> info@residio.tech</div>
                        </div>
                        <PopupBackLine page="contact us" />
                    </div>
                    <PopupCloseIcon/>
                </div>
            </div>
        );
    }
}
export default ContactInfoPopup;
