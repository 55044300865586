import React from 'react';
import './service.scss';
import Button from '../../components/button/button';

class Services extends React.Component{
    render() {
        return (
            <div className="container">
                <div className="s-bg-block col-xl-6 col-lg-6 col-md-12">
                    <div className="line-text">
                        <div className="line"/>
                        Residio.tech technology is paired with wide range of services offered to its franchisees
                    </div>
                    <div className="s-i-div">
                        <img src={process.env.PUBLIC_URL +"/assets/img/services/icons/icon1.png"} alt="#"/>
                            <div className="s-i-texts">
                                <div className="title">Planning and construction support</div>
                                <ul>
                                    <li>Master planning and design optimization</li>
                                    <li>Financial planning including financing / leasing</li>
                                </ul>
                            </div>
                    </div>
                    <div className="s-i-div">
                        <img src={process.env.PUBLIC_URL +"/assets/img/services/icons/icon2.png"} alt="#"/>
                            <div className="s-i-texts">
                                <div className="title">Cloud based IT system</div>
                                <ul>
                                    <li>Inventory management, pricing, bookings</li>
                                    <li>Developed and fine-tuned for Residio.tech needs</li>
                                </ul>
                            </div>
                    </div>
                    <div className="s-i-div">
                        <img src={process.env.PUBLIC_URL +"/assets/img/services/icons/icon3.png"} alt="#"/>
                            <div className="s-i-texts">
                                <div className="title">Call center</div>
                                <ul>
                                    <li>Call center (inbound and outbound)</li>
                                    <li>Centralized customer relationship management</li>
                                </ul>
                            </div>
                    </div>
                    <div className="s-i-div">
                        <img src={process.env.PUBLIC_URL +"/assets/img/services/icons/icon4.png"} alt="#"/>
                            <div className="s-i-texts">
                                <div className="title">Operating support</div>
                                <ul>
                                    <li>Proven and tested operating concept</li>
                                    <li>Staff trainings</li>
                                    <li>Revenue and profit maximization</li>
                                </ul>
                            </div>
                    </div>
                </div>
                <Button popup_name='services' text='learn more' setPopupClass='service-popup service-popup-active' />
            </div>
        );
    }
}

export default Services;
