import React from 'react';
import './unload.scss';

class UnloadPopup extends React.Component{
    state = {
        show_class: 'unload-info-popup-open',
    };
    closeWindow = () => {
        console.log('close');
        window.close();
    };

    render() {
        return (
            <div className={"contact-info-popup " +  this.state.show_class}>
                <div className="popup-lay">
                    <div className="content">
                        <div className="content-block">
                            <div className="head-text">If you leave the page you lose your changes. <br/>Leave?</div>
                            <div className="body-text">
                                <div className="btn btn-danger" onClick={() => this.closeWindow()}>YES</div>
                                <div className="btn btn-primary" onClick={() => this.setState({show_class: ''})}>NO</div>
                            </div>
                        </div>
                    </div>
                    <div className="close" onClick={() => this.setState({show_class: ''})}><img src={process.env.PUBLIC_URL +"/assets/img/close.png"} alt="#"/></div>
                </div>
            </div>
        );
    }
}
export default UnloadPopup;
