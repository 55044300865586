import React from 'react';
import './error.scss';
import PopupBackLine from '../../popup-back-line';
import PopupCloseIcon from '../../popup-close-icon';

class ContactErrorPopup extends React.Component{

    render() {
        return (
                <div className="contact-popup contact-popup-open">
                    <div className="popup-lay">
                        <div className="error">
                            <img src={process.env.PUBLIC_URL +"/assets/img/popup/error.png"} alt="#" />
                                <div className="head-text">Something goes wrong, please try again</div>
                                <PopupBackLine page="contact us" />
                        </div>
                        <PopupCloseIcon/>
                    </div>

                </div>
        );
    }
}
export default ContactErrorPopup;
