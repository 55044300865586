import React from 'react';
import './terms-of-use-info.scss';
import PopupBackLine from '../../popup-back-line';
import PopupCloseIcon from '../../popup-close-icon';

class ContactTermOfUsePopup extends React.Component{
    componentDidMount() {
        document.querySelector('.terms-of-use-info-popup').addEventListener('wheel', ((e) =>{
            let elem = document.querySelector('.terms-of-use-info-popup .content-block');
            if(e.deltaY < 0)  {
                elem.scrollTop -= 40;
            } else {
                elem.scrollTop += 40;
            }
        }));
    }
    render() {
        return (
            <div className="terms-of-use-info-popup terms-of-use-info-popup-open">
                <div className="popup-lay">
                    <div className="content">
                        <PopupBackLine page="contact us" />
                        <div className="content-block">
                            <div className="head-text">Terms of Use</div>
                            <div className="subhead-text">Last updated: April 25, 2019</div>
                            <div className="body-text">Please read these Terms of Use ("Terms", "Terms of Use")
                                carefully before using
                                the http://residio.tech website and related mobile applications (the "Sites") operated
                                by
                                Residio.tech.
                            </div>
                            <div className="body-text">Your access to and use of the Service is conditioned on your
                                acceptance of and
                                compliance with these Terms. These Terms apply to all visitors, users and others who
                                access or use
                                the Service.
                            </div>
                            <div className="body-text"><strong>By accessing or using the Service you agree to be bound
                                by these Terms.
                                If you disagree with any part of the terms then you may not access the Service.</strong>
                            </div>
                            <div className="body-text">The content and information displayed on the Sites are
                                Residio.tech‘s property
                                and are collectively referred to as “Residio.tech Information”. The downloading,
                                reproduction, or
                                retransmission of Residio.tech Information, other than for non-commercial individual
                                use, is
                                strictly prohibited, except to the extent permitted by travel agents for business-only
                                purposes in
                                accordance with section below.
                            </div>
                            <div className="body-text">By using our Sites you are accepting all the terms and conditions
                                set forth below
                                in these Terms of Use. If you do not agree to each and all of these terms and conditions
                                please do
                                not use our Sites. We reserve the right, at our discretion, to change and or modify
                                portions of
                                these Terms of Use at any time without notice and, unless otherwise indicated, such
                                changes will
                                become effective immediately upon posting; therefore, please check these Terms of Use
                                periodically
                                for changes. We will display the effective date of these Terms of Use at the top of this
                                page.
                            </div>
                            <div className="body-text">Our Sites may contain or reference trademarks, patents,
                                copyrighted materials,
                                trade secrets, technologies, products, processes or other proprietary rights of
                                Residio.tech, and/or
                                other parties. No license to or right in any such trademarks, patents, copyrighted
                                materials, trade
                                secrets, technologies, products, processes and other proprietary rights of Residio.tech
                                and/or other
                                parties is granted to or conferred upon you.
                            </div>
                            <div className="body-text">You agree that you will not use any device, software or other
                                instrumentality to
                                interfere or attempt to interfere with the proper working of our Sites, and that you
                                will not take
                                any action that imposes an unreasonable or disproportionately large load on our
                                infrastructure. In
                                addition, you agree that you will not use any robot, spider, other automatic device, or
                                manual
                                process to monitor, scrape, or copy our Sites or the Residio.tech Information contained
                                therein, or
                                any aspect of the Sites or the Residio.tech Information, without the prior express
                                consent from an
                                authorized Residio.tech representative (such consent is deemed given for standard search
                                engine
                                technology employed by Internet search websites to direct Internet users to our Sites).
                                Furthermore,
                                you agree that you will not use our Sites for any purpose that is illegal, unlawful, or
                                prohibited
                                by these Terms of Use. You agree that you will not interrupt, disrupt, alter, destroy,
                                impair,
                                restrict tamper, or otherwise affect the proper operation of our Sites in any way,
                                including,
                                without limitation, through the use of any malicious or unauthorized code, virus, worm,
                                Trojan
                                horse, malware, or program. You may not use our Sites in any threatening, libelous,
                                slanderous,
                                defamatory, obscene, inflammatory, pornographic, discriminatory, or otherwise offensive
                                manner. We
                                reserve the right in our sole discretion to revoke or deny your access to our Sites,
                                including,
                                without limitation, if you violate any of the provisions of these Terms of Use.
                            </div>
                            <div className="body-text">You agree that you will make only legitimate reservations in good
                                faith for use
                                by you and your invited guests only, and not for other purposes, including without
                                limitation,
                                reselling, impermissibly assigning or posting on third party websites, or making
                                speculative, false
                                or fraudulent reservations, or any reservation in anticipation of demand.
                            </div>
                            <div className="body-text">Our Sites may provide you with the ability to use usernames,
                                passwords, or other
                                codes or devices to gain access to certain portions of our Sites (“access codes”). You
                                are entirely
                                responsible for maintaining the confidentiality of your access codes and for all
                                activities that
                                occur under your account. We reserve the right to immediately terminate your account in
                                our sole
                                discretion and without prior notice to you wherever there is a reason including, for
                                example, if you
                                violate any part of these Terms of Use.
                            </div>
                            <div className="body-text">TO THE MAXIMUM EXTENT PERMITTED BY LAW, OUR SITES AND
                                RESIDIO.TECH INFORMATION
                                ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING,
                                BUT NOT
                                LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                                OR
                                NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR ANY GUARANTY OR ASSURANCE THAT OUR SITES
                                AND/OR
                                RESIDIO.TECH INFORMATION WILL BE AVAILABLE, ADEQUATE, ACCURATE, UNINTERRUPTED, COMPLETE
                                OR ERROR
                                FREE. WE ARE NEITHER RESPONSIBLE NOR LIABLE FOR ANY MALICIOUS OR UNAUTHORIZED CODE AND
                                YOU ARE
                                SOLELY RESPONSIBLE FOR ENSURING YOU HAVE APPROPRIATE SCANNING AND PROTECTIVE MECHANISMS
                                FOR THE
                                SECURITY OF YOUR DEVICES, PROGRAMS AND INFORMATION. BY USING ANY OF OUR SITES AND/OR
                                RESIDIO.TECH
                                INFORMATION, YOU ARE ASSUMING ALL RISK OF LOSS THAT MAY ARISE OR BE ASSOCIATED WITH THAT
                                USE.
                            </div>
                            <div className="body-text">
                                To the maximum extent permitted by law, we, other members of our group of companies and
                                third
                                parties connected to us hereby expressly exclude any liability for any direct, indirect
                                or
                                consequential loss or damage incurred by any user in connection with our Sites or in
                                connection with
                                the use, inability to use, or results of the use of our Sites, any websites linked to
                                them and any
                                materials posted on them, including, without limitation any liability for loss of income
                                or revenue;
                                loss of business; loss of profits or contracts; loss of anticipated savings; loss of
                                data; loss of
                                goodwill; wasted management or office time; and for any other loss or damage of any
                                kind, however
                                arising and whether caused by tort (including negligence), breach of contract or
                                otherwise, even if
                                foreseeable, provided that this condition shall not prevent claims for loss of or damage
                                to your
                                tangible property or any other claims for direct financial loss that are not excluded by
                                any of the
                                categories set out above.
                            </div>
                            <div className="body-text">This does not affect our liability for death or personal injury
                                arising from our
                                negligence, nor our liability for fraudulent misrepresentation or misrepresentation as
                                to a
                                fundamental matter, nor any other liability that cannot be excluded or limited under
                                applicable law.
                            </div>
                            <div className="body-text">You shall defend us against any demands, claims or actions
                                brought against us or
                                arising as a result of any breach or violation of these Terms of Use by you (“Claim”)
                                and you shall
                                indemnify and hold us harmless from and against any and all losses, damages, costs and
                                expenses
                                (including attorneys' fees) resulting from any such Claim. We have the right, at our
                                expense, to
                                assume exclusive defense against any Claim and all negotiations for settlement and you
                                agree to
                                cooperate with us in the defense of any such Claim, at our request.
                            </div>
                            <div className="body-text">With respect to all communications you make to us regarding
                                Residio.tech
                                Information including but not limited to feedback, questions, comments, suggestions and
                                the like:
                                (a) you shall have no right of confidentiality in your communications and we shall have
                                no
                                obligation to protect your communications from disclosure; (b) we shall be free to
                                reproduce, use,
                                disclose and distribute your communications to others without limitation; and (c) we
                                shall be free
                                to use any ideas, concepts, know-how, content or techniques contained in your
                                communications for any
                                purpose whatsoever, including but not limited to the development, production and
                                marketing of
                                products and services that incorporate such information. The above is limited only by
                                our commitment
                                and obligations pertaining to your personal information (for more information, please
                                see our
                                Privacy Statement).
                            </div>
                            <div className="body-text">Residio.tech Information may contain technical inaccuracies and
                                typographical
                                errors, including but not limited to inaccuracies relating to pricing or availability
                                applicable to
                                your transaction. Residio.tech does not assumes responsibility or liability for any such
                                inaccuracies, errors or omissions, and shall have no obligation to honor reservations or
                                information
                                affected by such inaccuracies. Residio.tech reserves the right to make changes,
                                corrections,
                                cancellations and/or improvements to Residio.tech Information, and to the products and
                                programs
                                described in such information, at any time without notice, including after confirmation
                                of a
                                transaction.
                            </div>
                            <div className="body-text">Some areas of our Sites are restricted to travel agents or other
                                authorized
                                representatives. The Residio.tech Information contained in such restricted areas is
                                confidential to
                                Residio.tech, and is provided for business use only, which includes reviewing commission
                                status and
                                statements, and enrolling in our Electronic Funds Transfer program as described below.
                                We reserve
                                the right to prohibit access to, or use of, these restricted areas where we determine
                                that such use
                                or access interferes with our Sites’ operations or that such use or access results in
                                commercial
                                benefits or other entities to our detriment. If you are a travel agent or authorized
                                representative
                                of a travel agent using our Sites the following apply:
                            </div>
                            <div className="body-text">Passwords: We have provided you with a unique password so that
                                access to
                                information related to your agency and transactions can be restricted to only those
                                individuals to
                                whom you provided the password. It is your responsibility to protect the confidentiality
                                of the
                                password. Residio.tech does not assume any liability for any access to information or
                                transactions
                                that are executed with the password provided to you. We strongly recommend that you
                                change the
                                password on a periodic basis and whenever a travel agent leaves your travel agency or
                                you suspect
                                that knowledge of the password is no longer limited to those whom you have authorized.
                            </div>
                            <div className="body-text">Electronic Funds Transfer (EFT): Residio.tech may be able to make
                                commission
                                payments by electronic funds transfer (EFT) through the Automated Clearing House (ACH)
                                System. If
                                you complete the EFT registration process provided on our Sites, completion of the
                                process signifies
                                that you: (1) authorize Residio.tech to make payments by EFT; (2) certify that you have
                                selected the
                                designated depository institution and provided the correct account information; and (3)
                                accept that
                                all such EFTs will occur in accordance with the following: (i) you will give thirty (30)
                                days’
                                advance notice to Residio.tech of any changes regarding your depository institution or
                                other payment
                                instructions; and (ii) you accept that all U.S. direct deposits are made with a payment
                                type of
                                “CTX”.
                            </div>
                            <div className="body-text">Our Sites contain information on Residio.tech worldwide products
                                and services,
                                not all of which are available in every location. A reference to a Residio.tech product
                                or service
                                on one of our Sites does not imply that such product or service is or will be available
                                in your
                                location.
                            </div>
                            <div className="body-text">If you choose to leave our Sites via links to other third party
                                websites,
                                including those of advertisers, our Terms of Use and our Privacy Statement will no
                                longer apply. We
                                are not responsible for the terms of use or privacy policies of those third-party
                                websites or the
                                cookies they use. In addition, because we have no control over such third-party sites
                                and resources,
                                you acknowledge and agree that Residio.tech is not responsible for the availability of
                                such
                                third-party sites or resources, and that Residio.tech does not endorse or are
                                responsible or liable
                                for any content, advertising, products, or other materials on or available from such
                                third party
                                sites or resources.
                            </div>
                            <div className="body-text">If you would like to view the privacy practices that govern how
                                we process
                                information about you on our Sites please review our Privacy Statement.
                            </div>
                            <div className="body-text">If you have questions about these Terms of Use please send an
                                e-mail to
                                info@Residio.tech
                            </div>
                        </div>
                    </div>
                    <PopupCloseIcon/>
                </div>
            </div>
        );
    }
}
export default ContactTermOfUsePopup;
