import React from 'react';
import './form.scss';
import PopupBackLine from '../../popup-back-line';
import PopupCloseIcon from '../../popup-close-icon';

class ContactFormPopup extends React.Component{

    render() {
        return (
                <div className="contact-popup contact-popup-open">
                    <div className="popup-lay">
                        <div className="success">
                            <img src={process.env.PUBLIC_URL +"/assets/img/popup/success.png"} alt="#" />
                                <div className="head-text">Thank you for contacting us, we will reply <br/> as soon as
                                    possible</div>
                                <PopupBackLine page="contact us" />
                        </div>
                        <PopupCloseIcon/>
                    </div>

                </div>
        );
    }
}
export default ContactFormPopup;
