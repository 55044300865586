import React from 'react';
import { FilePond } from 'react-filepond';
import './file-upload.scss';
import 'filepond/dist/filepond.min.css';

class FileUpload extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            labelText: '+ Click to add attachments',
            show_trash: false,
            files: []
        };
    }
    clearState = () => {
        this.setState({
            labelText: '+ Click to add attachments',
            files: []
        })
    }
    render() {
        return (
            <>
                <div className="page-btn upload-wrapper">
                    <div className="btn btn-default ">
                        <div className="upload-text">{this.state.labelText}</div>
                        <FilePond
                            labelIdle=''
                            labelInvalidField=''
                            labelFileWaitingForSize={''}
                            labelFileSizeNotAvailable={''}
                            allowMultiple={true}
                            files={this.state.files}
                            onupdatefiles={(fileItems) => {
                                this.setState({files: fileItems.map(fileItem => fileItem.file)});
                                if(this.state.files.length === 0){
                                    this.setState({
                                        labelText: '+ Click to add attachments',
                                        show_trash: false
                                    });
                                } else {
                                    this.setState({
                                        labelText: <><img src={process.env.PUBLIC_URL +'/assets/img/contact/clip.png'} alt='#'
                                        /> {this.state.files.length} files added</>,
                                        show_trash: true
                                    });
                                    this.props.onChange(this.state.files);
                                }
                            }}
                        />
                    </div>
                    {this.state.show_trash && <img src={process.env.PUBLIC_URL +'/assets/img/contact/trash.png'} alt='#'
                                                   className="fa-trash-alt" onClick={this.clearState}/>}
                </div>
            </>
        )
    }
}


export default FileUpload;
