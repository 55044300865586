import React from 'react';
import './solution.scss';
import Button from '../../components/button/button';

class Solution extends React.Component{
    render() {
        return (
                <div className="container">
                    <div className="cards-s">
                        <div className="card-s">
                            <div className="image"
                                 style={{backgroundImage: 'url(/assets/img/solution/solution_box.png)'}}/>
                            <div className="title">One bedroom unit</div>
                            <div className="big-text-c">
                                Single 20' unit with built-in kitchen and bathroom
                            </div>
                            <div className="group-block">
                                <div className="list">
                                    <div className="line-s">Area 47 sqm (incl. deck)</div>
                                    <div className="line-s">With portable fun jacuzzi</div>
                                </div>
                                <img src={process.env.PUBLIC_URL +"/assets/img/solution/image1.png"} alt="#"/>
                            </div>
                        </div>
                        <div className="card-s">
                            <div className="image"
                                 style={{backgroundImage: 'url(/assets/img/solution/solution_house.png)'}}/>
                            <div className="title">Multi bedroom unit</div>
                            <div className="big-text-c">
                                Multiple 20’ units with outdoor kitchen and portable jacuzzi
                            </div>
                            <div className="group-block">
                                <div className="list">
                                    <div className="line-s">2-bedroom unit: 145 sqm<sup>(1)</sup></div>
                                    <div className="line-s">3-bedroom unit: 192 sqm<sup>(1)</sup> <br/>incl. deck</div>
                                </div>
                                <img src={process.env.PUBLIC_URL +"/assets/img/solution/image2.png"} alt="#"/>
                            </div>
                        </div>
                        <div className="card-s">
                            <div className="image"

                                 style={{backgroundImage: 'url(/assets/img/solution/solution_boxes.png)'}}/>
                            <div className="title">Multi story hotel</div>
                            <div className="big-text-c">
                                Modular hotel up to 5 floors
                            </div>
                            <div className="group-block">
                                <div className="list">
                                    <div className="line-s">Room size: 27 sqm</div>
                                    <div className="line-s">Balcony: 16 sqm</div>
                                </div>
                                <img src={process.env.PUBLIC_URL +"/assets/img/solution/image3.png"} alt="#"/>
                            </div>
                        </div>

                    </div>
                    <div className="line-text-block">
                        <div className="text-l-s">
                            Other mobile hospitality and housing solutions offered by us include units for reception,
                            storage, technical, portable jacuzzis, roofing, decking, water storage and waste water
                            treatment solutions
                        </div>
                        <Button popup_name='solution' text='learn more' setPopupClass='solution-popup solution-popup-active' />
                    </div>
                </div>
    );
    }
}

export default Solution;
