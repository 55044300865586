import React from 'react';
import './popup.scss';
import {AppContext} from '../../App';
import FranchisingPopup from './franchising/franchising';
import ProjectPopup from './project/project';
import TechnologyPopup from './technology/technology';
import SolutionPopup from './solution/solution';
import ServicesPopup from './services/services';
import ContactInfoPopup from './contact/info/info';
import ContactTermOfUsePopup from './contact/terms-of-use-info/terms-of-use-info';
import ContactPrivacyPopup from './contact/privacy-info/privacy-info-popup';
import PhotoViewPopup from './photo_view/photo_view_popup';
import ContactFormPopup from './contact/form/form';
import ContactErrorPopup from './contact/error/error';
import CountrySelect from './contact/country-select/country-select';
import SelectPopup from './select-popup/select-popup';
import ExitPopup from "./exit/exit";

class Popup extends React.Component{
    settings = {
        infinite: false,
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dots: true,
        afterChange: (currentSlide) => {
            let i = (currentSlide ? currentSlide : 0) + 1;
            document.getElementsByClassName('slick-dots')[0]
                .setAttribute('data-before', '0'+i);
        }
    };
    wheelScroll(element) {
        document.getElementById('popup-wrapper').addEventListener('wheel', ((e) =>{
            if(e.deltaY < 0)  {
                element.slickPrev();
            } else {
                element.slickNext();
            }
        }));
        setTimeout(() => {
            document.querySelector('.popup-wrapper .slick-dots .up').addEventListener('click', () => {
                element.slickPrev();
            });
            document.querySelector('.popup-wrapper .slick-dots .down').addEventListener('click', () => {
                element.slickNext();
            });
        }, );
    }
    popupModify = () => {
        let dots = document.getElementsByClassName('slick-dots')[0];

        dots.setAttribute('data-before', '01');
        dots.insertAdjacentHTML("beforeEnd", ('<div class="appended"><div class="navigation">' +
            '<img class="up" src="/assets/img/popup/up.png" alt="#"/><img class="down" src="/assets/img/popup/down.png" alt="#"/>' +
            '</div><div class="text">Scroll down</div></div>'));
    };

    render() {
        return (
            <AppContext.Consumer>{(context) => (
                <div id="popup-wrapper">
                    {context.popup_name === 'project' && <ProjectPopup whellScroll={this.wheelScroll}
                            popupModyfy={this.popupModify} settings={this.settings}
                    />}
                    {context.popup_name === 'franchising' && <FranchisingPopup whellScroll={this.wheelScroll}
                        popupModyfy={this.popupModify} settings={this.settings}
                    />}
                    {context.popup_name === 'technology' && <TechnologyPopup whellScroll={this.wheelScroll}
                        popupModyfy={this.popupModify} settings={this.settings}
                        />}
                    {context.popup_name === 'solution' && <SolutionPopup whellScroll={this.wheelScroll}
                        popupModyfy={this.popupModify} settings={this.settings}
                    />}
                    {context.popup_name === 'services' && <ServicesPopup whellScroll={this.wheelScroll}
                        popupModyfy={this.popupModify} settings={this.settings}
                    />}
                    {context.popup_name === 'contact_info' && <ContactInfoPopup/>}
                    {context.popup_name === 'contact_term' && <ContactTermOfUsePopup/>}
                    {context.popup_name === 'contact_privacy_info' && <ContactPrivacyPopup/>}
                    {context.popup_name === 'photo_view' && <PhotoViewPopup photo_url={context.popup_view_photo_url}/>}
                    {context.popup_name === 'form_submitted' && <ContactFormPopup/>}
                    {context.popup_name === 'form_error' && <ContactErrorPopup/>}
                    {context.popup_name === 'country-select' && <CountrySelect/>}
                    {context.popup_name === 'popup-select' && <SelectPopup/>}
                    {context.popup_name === 'exit-popup' && <ExitPopup/>}
                </div>
            )}</AppContext.Consumer>
        )}
}
export default Popup;
