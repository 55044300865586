import React from 'react';
import './services.scss';
import Slider from "react-slick";
import PopupBackLine from '../popup-back-line';
import PopupCloseIcon from '../popup-close-icon';

class ServicesPopup extends React.Component{
    componentDidMount() {
        this.props.popupModyfy();
        this.props.whellScroll(this.slider);
    };

    render() {
        const settings = this.props.settings;
        return (
            <div className='container'>
                <div className="elements">
                    <Slider ref={c => (this.slider = c)}  {...settings} className='slick-slider'>
                        <div className="p-slide service-slide service-slide1">
                            <PopupBackLine page="services" />
                            <div className="content-upfrond">
                                <div className="head-text">During the planning and design phase, Residio.tech helps
                                    franchisees maximize the potential of their hospitality project and optimize
                                    return on their investment
                                </div>
                                <div className="c-content">
                                    <div className="photo photo1">
                                        <div className="photo-caption">Planning and projecting</div>
                                    </div>
                                    <div className="list-items">
                                        <div className="text-blue">Residio.tech services and assistance</div>
                                        <div className="item">
                                            <div className="big-number">01</div>
                                            <div className="texts">
                                                <div className="title">Masterplan development</div>
                                                <div className="list">
                                                    <div className="line">Maximizing potential of land</div>
                                                    <div className="line">Maximizing revenue-generating and minimizing
                                                        back-office
                                                        areas​
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="big-number">02</div>
                                            <div className="texts">
                                                <div className="title">Product specification</div>
                                                <div className="list">
                                                    <div className="line">Selection of finishing materials (cladding,
                                                        flooring,
                                                        tiling)
                                                    </div>
                                                    <div className="line">Selection of furnishings</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="big-number">03</div>
                                            <div className="texts">
                                                <div className="title">Business plan development</div>
                                                <div className="list">
                                                    <div className="line">Products and services selection</div>
                                                    <div className="line">Pricing and discount policy</div>
                                                    <div className="line">Big data based revenue and profit maximization
                                                        planning
                                                        tools
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="big-number">04</div>
                                            <div className="texts">
                                                <div className="title title-center">Project plan development</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-slide service-slide service-slide2">
                            <PopupBackLine page="services" />
                            <div className="content-upfrond">
                                <div className="head-text">During construction  phase, Residio.tech support focused on
                                    fine-turing of operating model and project management
                                </div>
                                <div className="c-content">
                                    <div className="photo photo2">
                                        <div className="photo-caption">Development and construction</div>
                                    </div>
                                    <div className="list-items">
                                        <div className="text-blue">Residio.tech services and assistance</div>
                                        <div className="item">
                                            <div className="big-number">01</div>
                                            <div className="texts">
                                                <div className="title title-center title-center-lg">Project management
                                                    assistance and product quality assurance
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="big-number">02</div>
                                            <div className="texts">
                                                <div className="title">Customization and installation of cloud based IT
                                                    system
                                                </div>
                                                <div className="list">
                                                    <div className="line">Management of your project – anytime,
                                                        anywhere
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="big-number">03</div>
                                            <div className="texts">
                                                <div className="title title-center">Setting relationship with OTAs +
                                                    integration of systems
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="big-number">04</div>
                                            <div className="texts">
                                                <div className="title title-center">Staff training</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-slide service-slide service-slide3">
                            <PopupBackLine page="services" />
                            <div className="content-upfrond">
                                <div className="head-text">Residio.tech helps franchisees optimize their operations and
                                    maximize potential of their business
                                </div>
                                <div className="c-content">
                                    <div className="photo photo3">
                                        <div className="photo-caption">Operation</div>
                                    </div>
                                    <div className="list-items">
                                        <div className="text-blue">Residio.tech services and assistance</div>
                                        <div className="item">
                                            <div className="big-number">01</div>
                                            <div className="texts">
                                                <div className="title">Customer service and customer relationship
                                                    management
                                                </div>
                                                <div className="list">
                                                    <div className="line">Via centralized service center</div>
                                                    <div className="line">Loyalty program aimed at customer retention
                                                    </div>
                                                    <div className="line">Cross-selling across group projects</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="big-number">02</div>
                                            <div className="texts">
                                                <div className="title">Big data based pricing and revenue management
                                                </div>
                                                <div className="list">
                                                    <div className="line">Pooling and analyzing data from all operations
                                                        with the aim of maximizing franchisees’ revenue and profit
                                                        potential
                                                    </div>
                                                    <div className="line">Centralized treasury</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="big-number">03</div>
                                            <div className="texts">
                                                <div className="title">Operations optimization</div>
                                                <div className="list">
                                                    <div className="line">Optimizing selection of offered products and
                                                        services
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="item">
                                            <div className="big-number">04</div>
                                            <div className="texts">
                                                <div className="title">Staff development</div>
                                                <div className="list">
                                                    <div className="line">Staff evaluations and training</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                    <PopupCloseIcon/>
                </div>
            </div>
        );
    }
}
export default ServicesPopup;
