import React from 'react';
import './exit.scss';
import PopupCloseIcon from "../popup-close-icon";
import RadioInputCheck from "../../radio-check-input/radio-check-input";
import {Event} from "../../ga/ga";
import axios from "axios";

class ExitPopup extends React.Component{
    state = {
        email_valid : {show_error: false, message: ''}
    };

    formCheck = () => {
        // const elm = document.getElementById('mail-popup').value;
        // if (!elm) {
        //     this.setState({email_valid : {show_error: true, message: '*Please enter your email.'}});
        //     return false;
        // }
        //
        // if (typeof elm !== "undefined") {
        //     const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        //     if (!pattern.test(elm)) {
        //         this.setState({email_valid : {show_error: true, message: '*Please enter valid email.'}});
        //         return false;
        //     }else{
        //         this.setState({email_valid : {show_error: false, message: ''}})
        //         return true;
        //     }
        // }
        return true;
    };

    handleSubmit(event) {
        event.preventDefault();

        const data = new FormData(event.target);
        const url = process.env.REACT_APP_API_URL + '/exit/';
        this.formCheck();
        if (this.formCheck()) {
            Event('Exit Popup', 'Click', 'Send');

            axios({
                url: url,
                method: 'POST',
                data: data,
            }).then((response) => {
                // Reset form
                document.getElementById('popupForm').reset();
                document.getElementsByClassName('popup-lay')[0].getElementsByClassName('close')[0].click();
            }).catch((error) => {
                document.getElementsByClassName('popup-lay')[0].getElementsByClassName('close')[0].click();
            });
        }
    }

    render() {
        return (
            <div className={"exit-popup unload-info-popup-open"}>
                <div className="popup-lay">
                    <div className="content">
                            <div className="header">
                                <img src={process.env.PUBLIC_URL +"/assets/img/logo-w-new.png"} alt="#"/>
                                <div className="text">Thank you for your visit! Before you leave, please <br/> let us know your thoughts about Residio.tech</div>
                            </div>

                            <form id='popupForm' type="post"  className="else" onSubmit={(event) => {
                                this.handleSubmit(event)
                            }}>
                                <div className="text-blue">Would you like to stay in Residio.tech pop-up resort?</div>
                                <RadioInputCheck name='stay_in_residio' text='Absolutely! Love it!' id='Absolutely'/>
                                <RadioInputCheck name='stay_in_residio' text='Probably. If the location and price is right' id='Probably'/>
                                <RadioInputCheck name='stay_in_residio' text='Not sure. Not convinced about the concept' id='Not-sure'/>
                                <RadioInputCheck name='stay_in_residio' text='Never. Hate it' id='Never'/>

                                <hr/>

                                <div className="text-blue">Would you consider acquiring Residio.tech pop-up unit(s) in existing or planned project to receive a regular rental income?</div>
                                <RadioInputCheck name='acquiring' text='Sounds interesting! Tell me more!' id='Sounds'/>
                                <RadioInputCheck name='acquiring' text='Rather not. Nor my type of thing' id='Rather'/>
                                <RadioInputCheck name='acquiring' text='Can not say. Don’t understand' id='Don’t-understand'/>

                                <div className="text mobile-hidden">Your comments and suggestions (optional):</div>
                                <textarea name="comments" placeholder='Enter your comments'/>

                                <div className="text">Enter your email for a chance to win 1 week for 2 in Residio.tech resort (optional): </div>
                                <input name="email" type="text" placeholder='Enter your email' id='mail-popup'
                                       className={"form-control input-popup " + (this.state.email_valid.show_error ? 'input-error' : 'error')}/>
                                    <div className={(this.state.email_valid.show_error ? 'errorMsg' : 'hidden')}
                                    >{this.state.email_valid.message}</div>

                                <div className="elements">
                                    <div className="groups">
                                        <div className="text">Thank you, your feedback will help us improve our service! </div>
                                        <div className="text-ital">(*): Winners are announced monthly on our Facebook page</div>
                                    </div>
                                    <button>SEND</button>
                                </div>
                            </form>
                        <PopupCloseIcon/>
                    </div>
                </div>
            </div>
        );
    }
}
export default ExitPopup;
