import React from 'react';
import './project.scss';
import Slider from "react-slick";
import PopupBackLine from '../popup-back-line';
import PopupCloseIcon from '../popup-close-icon';

class ProjectPopup extends React.Component{
    componentDidMount() {
        this.props.popupModyfy();
        this.props.whellScroll(this.slider);
    }

    render() {
        const settings = this.props.settings;
        return (
            <div className='container'>
                <div className="elements">
                    <Slider ref={c => (this.slider = c)} {...settings}>
                        <div className="p-slide economical-slide">
                            <PopupBackLine page="projects" />
                            <div className="s-content">
                                <div className="p-elements">
                                    <div className="head-text">Development with Residio.tech technology is 50% more
                                        economical compared to traditional construction
                                    </div>
                                    <div className="text-list">
                                        <div className="item">
                                            <img src={process.env.PUBLIC_URL +"/assets/img/popup/icon.png"} alt="#"/>
                                                <div>Centralized manufacturing</div>
                                        </div>
                                        <div className="item">
                                            <img src={process.env.PUBLIC_URL +"/assets/img/popup/icon2.png"} alt="#"/>
                                                <div>Production automation</div>
                                        </div>
                                        <div className="item">
                                            <img src={process.env.PUBLIC_URL +"/assets/img/popup/icon3.png"} alt="#"/>
                                                <div>High volume purchasing</div>
                                        </div>
                                        <div className="item">
                                            <img src={process.env.PUBLIC_URL +"/assets/img/popup/icon4.png"} alt="#"/>
                                                <div>Product standartization</div>
                                        </div>
                                        <div className="item">
                                            <img src={process.env.PUBLIC_URL +"/assets/img/popup/icon5.png"} alt="#"/>
                                                <div>Lean production processes</div>
                                        </div>
                                    </div>
                                    <div className="b-text">
                                        Low project investment results in above-average profitability of Residio.tech
                                        projects​
                                    </div>
                                </div>
                                <div className="photo">
                                    <img src={process.env.PUBLIC_URL +"/assets/img/popup/progect-block.jpg"} alt="#" className="d-none d-xl-block"/>
                                        <img src={process.env.PUBLIC_URL +"/assets/img/popup/progect-block-small.jpg"}
                                             className="d-none d-lg-block d-xl-none" alt="#"/>
                                </div>
                            </div>
                        </div>

                        <div className="p-slide traditional-slide">
                            <PopupBackLine page="projects" />
                            <div className="head-text">With Residio.tech, hotel development time can be shortened from
                                typical 3 years to little over 1 year
                            </div>
                            <div className="photo">
                                <img src={process.env.PUBLIC_URL +"/assets/img/popup/project-second-block.png"} alt="#"
                                     className="d-none d-xl-block"/>
                                    <img src={process.env.PUBLIC_URL +"/assets/img/popup/project-second-block-small.png"}
                                         className="d-none d-lg-block d-xl-none" alt="#"/>
                            </div>
                            <div className="blue-text">
                                <div className="item">Simplified architectural works and design using modular concept​
                                </div>
                                <div className="item">Site works and construction (manufacturing) are taking place at
                                    the same time​
                                </div>
                            </div>
                        </div>

                        <div className="p-slide concept-slide">
                            <PopupBackLine page="projects" />
                            <div className="c-content">
                                <div className="photos">
                                    <div className="photo"/>
                                    <div className="photo"/>
                                </div>
                                <div className="texts">
                                    <div className="head-text">Residio.tech concept is suitable for family-operated hospitality projects
                                        (homestays, resort, hotels)
                                    </div>
                                    <div className="text-list">
                                        <div className="item">
                                            <img src={process.env.PUBLIC_URL +"/assets/img/popup/icon2.png"} alt="#"/>
                                                <div>Optimized and simplified procedures</div>
                                        </div>
                                        <div className="item">
                                            <img src={process.env.PUBLIC_URL +"/assets/img/popup/icon4.png"} alt="#"/>
                                                <div>Focus on profit-generating, value added activities based on data analysis
                                                </div>
                                        </div>
                                        <div className="item">
                                            <img src={process.env.PUBLIC_URL +"/assets/img/popup/icon6.png"} alt="#"/>
                                                <div>Low staff requirements, suitable for family operated businesses.
                                                    <small>
                                                        Staff required to operate 10 room hotel
                                                        <span>4 star: 12 people</span>
                                                        <span>3 star: 8 people</span>
                                                        <span><strong>Residio.tech: 5 people</strong></span>
                                                    </small>
                                                </div>
                                        </div>
                                        <div className="item">
                                            <img src={process.env.PUBLIC_URL +"/assets/img/popup/icon7.png"} alt="#"/>
                                                <div>Reduction of back office space by 50% - 75%</div>
                                        </div>
                                        <div className="item">
                                            <img src={process.env.PUBLIC_URL +"/assets/img/popup/icon8.png"} alt="#"/>
                                                <div>Centralized support for Residio.tech franchisees​
                                                    <small>
                                                        <span>Pricing, discounts and revenue management</span>
                                                        <span>Customer support and CRM via centralized services center</span>
                                                    </small>
                                                </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-slide investment-block">
                            <PopupBackLine page="projects" />
                            <div className="texts">
                                <div className="head-text">With Residio.tech mobile hospitality technologies, investment required to launch hotel or resort is significantly reduced.
                                    Low operator investment coupled with
                                    Residio.tech financing significantly increases return on investment​
                                </div>
                                <div className="head-text-next">​4 star hotel 12 keys</div>
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th scope="col">Column name</th>
                                        <th scope="col">Traditional construction</th>
                                        <th scope="col">
                                            <div><img src={process.env.PUBLIC_URL +"/assets/img/table-logo.png"} alt="#"/>Residio.tech franchise
                                            </div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Total investment (excluding land), USD</td>
                                        <td>1 150 000</td>
                                        <td>635 000</td>
                                    </tr>
                                    <tr>
                                        <td>Financing (leasing) amount<sup>(1)</sup>, USD</td>
                                        <td>0</td>
                                        <td>269 000</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Required operator equity (excl. land), USD</strong></td>
                                        <td><strong>1 150 000</strong></td>
                                        <td><strong>366 000</strong></td>
                                    </tr>
                                    <tr>
                                        <td>EBITDA, USD</td>
                                        <td>74 500</td>
                                        <td>58 000</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Investment yield/ROI<sup>(2)</sup>, %</strong></td>
                                        <td><strong>6.5</strong></td>
                                        <td><strong>15.8</strong></td>
                                    </tr>
                                    </tbody>
                                </table>
                                <div className="slider-footer">
                                    (1): for qualified projects based on approval of partnering financial institution; conditions apply <br/>
                                    (2): EBITDA / total franchisee investment
                                </div>
                            </div>
                        </div>
                    </Slider>
                    <PopupCloseIcon/>
                </div>
            </div>
        );
    }
}
export default ProjectPopup;
