import React from 'react';
import './select-popup.scss';
import PopupCloseIcon from '../popup-close-icon';
import {AppContext} from "../../../App";

class SelectPopup extends React.Component {

    render() {

        return (
            <AppContext.Consumer>{(context) => (
                <div className="select-modal select-modal-open">
                    <div className="popup-lay">
                        <div className="content">
                            <div className="items">
                                {context.select_options.value.map((elm) => {
                                    return <div onClick={() => {
                                        context.change_select_value(elm.text, context.select_options.belongs);
                                        context.toggle_popup();
                                    }}
                                                className='item'>
                                        {elm.text}
                                    </div>
                                })}
                            </div>
                            <PopupCloseIcon/>
                        </div>
                    </div>
                </div>
            )}</AppContext.Consumer>
        );
    }
}

export default SelectPopup;
