import React from 'react';
import {AppContext} from '../../App';

class PopupBackLine extends React.Component{

    render() {
        return (
            <AppContext.Consumer>{(context) => (
               <div className="back-line" onClick={context.toggle_popup}>
                   <img src={process.env.PUBLIC_URL +"/assets/img/popup/back.png"} alt="#"/> Back to {this.props.page}
               </div>
            )}</AppContext.Consumer>
        )}
}
export default PopupBackLine;
