import React from 'react';
import './radio-input.scss';

class RadioInput extends React.Component{
    render() {
        return (
                <label htmlFor={this.props.id}>
                    <input type="radio" value={this.props.text} name="interested_in" id={this.props.id}/> <span>{this.props.text}</span>
                </label>
        );
    }
}

export default RadioInput;
