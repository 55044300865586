import React from 'react';
import './menu.scss';

class Menu extends React.Component {
    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container">
                    <a className="navbar-brand" href="#projects">
                        <div className="logo"><img src={process.env.PUBLIC_URL + "/assets/img/logo-blue.png"} alt="#"/>
                        </div>
                    </a>
                    <a className="nav-item nav-link c-nav navbar-toggler" data-menuanchor="contact"
                       href="#contact"><span className="contact-nav">CONTACT US</span></a>
                    <button className="navbar-toggler collapsed" type="button" data-toggle="collapse"
                            data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <div className="navbar-nav nav-desctop">
                            <a data-menuanchor="projects" className="nav-item nav-link active projects-menu"
                               href="#projects">PROJECTS</a>
                            <a data-menuanchor="technology" className="nav-item nav-link"
                               href="#technology">TECHNOLOGY</a>
                            <a className="nav-item nav-link " data-menuanchor="solution" href="#solution">SOLUTIONS</a>
                            <a className="nav-item nav-link" data-menuanchor="services" href="#services">SERVICES</a>
                            <a data-menuanchor="vision" className="nav-item nav-link" href="#vision">VISIONS</a>
                            <a className="nav-item nav-link " data-menuanchor="franchising"
                               href="#franchising">FRANCHISING</a>
                            <a className="nav-item nav-link c-nav" data-menuanchor="contact" href="#contact"><span
                                className="contact-nav">CONTACT US</span></a>
                            <div className="bg-help"/>
                        </div>
                        <div className="navbar-nav nav-mobile">
                            <div  data-toggle="collapse" data-target=".navbar-collapse">
                                <a data-menuanchor="projects" className="nav-item nav-link active projects-menu"
                                   href="#projects">PROJECTS</a>
                            </div>
                            <div  data-toggle="collapse" data-target=".navbar-collapse">
                                <a data-menuanchor="technology" className="nav-item nav-link"
                                   href="#technology">TECHNOLOGY</a></div>
                            <div  data-toggle="collapse" data-target=".navbar-collapse">
                                <a className="nav-item nav-link " data-menuanchor="solution" href="#solution">SOLUTIONS</a>
                            </div>
                            <div  data-toggle="collapse" data-target=".navbar-collapse">
                                <a className="nav-item nav-link" data-menuanchor="services" href="#services">SERVICES</a>
                            </div>
                            <div  data-toggle="collapse" data-target=".navbar-collapse">
                                <a data-menuanchor="vision" className="nav-item nav-link" href="#vision">VISIONS</a>
                            </div>
                            <div  data-toggle="collapse" data-target=".navbar-collapse">
                                <a className="nav-item nav-link " data-menuanchor="franchising"
                                   href="#franchising">FRANCHISING</a>
                            </div>
                            <div  data-toggle="collapse" data-target=".navbar-collapse">
                                <a className="nav-item nav-link c-nav" data-menuanchor="contact" href="#contact"><span
                                    className="contact-nav">CONTACT US</span></a>
                            </div>
                            <div className="bg-help"/>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}

export default Menu;
