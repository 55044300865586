import React from 'react';
import {AppContext} from '../../App';

class PopupCloseIcon extends React.Component{

    render() {
        return (
            <AppContext.Consumer>{(context) => (
                <div className="close" onClick={context.toggle_popup}><img src={process.env.PUBLIC_URL +"/assets/img/close.png"} alt="#"/></div>
            )}</AppContext.Consumer>
        )}
}
export default PopupCloseIcon;
