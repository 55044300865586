import React from 'react';
import {AppContext} from "../../App";
import {ModalView} from "../ga/ga";

class Button extends React.Component{
    setGA = () => {
        if (this.props.is_project) {
            ModalView(this.props.popup_name + '/' + this.props.project_name + '/learn_more');
        } else {
            ModalView(this.props.popup_name + '/learn_more');
        }
    }

    setPopupClass = () => {
        if(!!this.props.setPopupClass) {
            setTimeout(() => {
                    document.getElementById('popup-wrapper')
                        .className = 'popup-wrapper '  + this.props.setPopupClass;
            })
        }
    };

    render() {
        return (
            <AppContext.Consumer>{(context) => (
                <div className={this.props.is_project ?  'slider-btn' :'page-btn' } onClick={() => {
                    context.toggle_popup(this.props.popup_name); this.setPopupClass(); this.setGA()}}
                >
                    <button className="btn btn-default">{this.props.text}</button>
                </div>
            )}</AppContext.Consumer>
        )
    }
}


export default Button;
