import React from 'react';
import './vision.scss';
import {AppContext} from "../../App";
import {ModalView} from "../../components/ga/ga";

class Vision extends React.Component{
    render() {
        return (
            <AppContext.Consumer >{(context) => (
                <div className="vision-block">
                    <div className="container">
                        <div className="vision-collage">
                            <a href="/#vision" className="img-block" onClick={() => {
                                context.change_popup_view_url('/assets/img/vision/slide.jpg');
                                context.toggle_popup('photo_view');
                                ModalView('vision/ecological_resort/photo_view');
                            }}>
                                <div className="text">Solar-powered, ecological resort</div>
                                <img src={process.env.PUBLIC_URL +"/assets/img/vision/image-min.png"} alt="#"/>
                            </a>
                            <div className="img-block 1" onClick={() => {
                                context.change_popup_view_url('/assets/img/vision/slide-2.jpg');
                                context.toggle_popup('photo_view');
                                ModalView('vision/cliff_bungalows/photo_view');
                            }}>
                                <div className="text">Cliff bungalows</div>
                                <img src={process.env.PUBLIC_URL +"/assets/img/vision/image2-min.png"} alt="#"/>
                            </div>
                        </div>
                        <div className="vision-collage">
                            <div className="img-block 2" onClick={() => {
                                context.change_popup_view_url('/assets/img/vision/slide-3.jpg');
                                context.toggle_popup('photo_view');
                                ModalView('vision/arctic_base/photo_view');
                            }}>
                                <div className="text">Arctic base</div>
                                <img src={process.env.PUBLIC_URL +"/assets/img/vision/image3-min.png"} alt="#"/>
                            </div>
                            <div className="img-block 3" onClick={() => {
                                context.change_popup_view_url('/assets/img/vision/slide-4.jpg');
                                context.toggle_popup('photo_view');
                                ModalView('vision/water_villas/photo_view');
                            }}>
                                <div className="text">Water villas</div>
                                <img src={process.env.PUBLIC_URL +"/assets/img/vision/image4-min.png"} alt="#"/>
                            </div>
                        </div>
                    </div>
                </div>
            )}</AppContext.Consumer>
        );
    }
}

export default Vision;
