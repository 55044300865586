import React from 'react';
import "fullpage.js/vendors/scrolloverflow"; // Optional. When using scrollOverflow:true
import ReactFullpage from "@fullpage/react-fullpage";

import Technology from '../pages/technology/technology';
import Project from '../pages/projects/projects';
import Solution from '../pages/solution/solution';
import Services from '../pages/service/service';
import Vision from '../pages/vision/vision';
import Franchising from '../pages/franchising/franchising'
import Contact from '../pages/contact/contact';
import {PageView} from "../components/ga/ga";

import "fullpage.js/vendors/fullpage.continuousHorizontal.min";


const fullPageOptions = {
    className: 'content',
    scrollOverflow: true,
    scrollOverflowOptions: {
        click:false
    },
    //scrollBar: true,
    //responsiveSlides: true,
    css3: true,
    fadingEffect: true,
    continuousHorizontal: true,
    continuousHorizontalKey:'cmVzaWRpby50ZWNoXzJJTVkyOXVkR2x1ZFc5MWMwaHZjbWw2YjI1MFlXdz0xVWQ=',
    anchors: ['projects', 'technology', 'solution', 'services', 'vision', 'franchising', 'contact'],
    menu: '#navbarNavAltMarkup',
    licenseKey: '391F604F-D59D4391-A2233F8C-6C8436DA',
    sectionSelector: '.slide-box',
    scrollHorizontally: true,
    // scrollOverflowReset: true,
    slidesNavigation: true,
    slidesNavPosition: 'bottom',
    afterLoad: (origin, destination, direction) => {
        console.log('some.log', origin);
    }
};

class FullpageLayout extends React.Component {
    sliderTimer;
    fullpageApi = {};
    state = {
        show: 'projects',
        previous: 'projects'
    };

    reBuild() {
        if(!!this.fullpageApi) {
            this.fullpageApi.reBuild()
        }
    }

    onLeave(origin, destination) {
        // for lazy load change state
        PageView(destination.anchor)
        this.setState({
            show: destination.anchor,
            previous: origin.anchor
        });
        this.reBuild();
    }

    autoScroll = () => {
        window.clearTimeout(this.sliderTimer);
        this.sliderTimer = window.setTimeout(() => {
            if (!this.props.popup_is_open) {
                this.fullpageApi.moveSlideRight();
            } else {
                this.autoScroll();
            }
        }, 6000);
    };
    componentDidMount() {
        // Listen for orientation changes
        let viewport = document.querySelector("meta[name=viewport]");
        window.addEventListener("orientationchange", () => {
            if(Math.abs(window.orientation) === 90) {
                setTimeout(() => {
                    let w = window.outerWidth, k = 1;
                    if (w < 1000) { k = w / 1000; }
                    viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale='+k+', user-scalable=0');
                    document.getElementById('root').classList.add('mobile-revert');
                    document.querySelector('.navbar').classList.remove('navbar-expand-lg');
                    this.fullpageApi.moveTo('projects', 1);
                    setTimeout(() => this.reBuild(), 500);
                }, 250)
            } else {
                viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1, user-scalable=0');
                document.getElementById('root').classList.remove('mobile-revert');
                document.querySelector('.navbar').classList.add('navbar-expand-lg');
                this.fullpageApi.moveTo('projects', 1);
                setTimeout(() => this.reBuild(), 500);
            }
        }, false);

        console.log(/Mobi/.test(navigator.userAgent));
        if (/Mobi/.test(navigator.userAgent)) {
            if(Math.abs(window.orientation) === 90) {
                let w = window.outerWidth, k = 1;
                if (w < 1000) { k = w / 1000; }
                viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale='+k+', user-scalable=0');
                document.getElementById('root').classList.add('mobile-revert');
                document.querySelector('.navbar').classList.remove('navbar-expand-lg');
            } else {
                viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, maximum-scale=1, user-scalable=0');
                document.getElementById('root').classList.remove('mobile-revert');
                document.querySelector('.navbar').classList.add('navbar-expand-lg');
            }
            this.reBuild();
        }
    }

    render() {
        return (
            <ReactFullpage
                {...fullPageOptions}
                afterLoad={(a) => {
                    this.autoScroll()
                }}
                onLeave={this.onLeave.bind(this)}
                onSlideLeave={() => {
                    this.autoScroll()
                }}
                render={({state, fullpageApi}) => {
                    this.fullpageApi = fullpageApi;
                    if (state.initialized) {
                        this.fullpageApi.setAllowScrolling(!this.props.popup_is_open);
                    }
                    return (
                        <ReactFullpage.Wrapper>
                            <div className="slide-box section content-box projects-box">
                                <Project/>
                            </div>
                            <div className="slide-box section  technology-box">
                                <div className="group content technology-group" style={{height: 'auto'}}>
                                    {(this.state.show === 'technology' || this.state.previous === 'technology') &&
                                    <Technology/>}
                                </div>
                            </div>
                            <div className="slide-box section  solutions-box">
                                    <div className='solution-block' style={{height: 'auto'}}>
                                        {(this.state.show === 'solution' || this.state.previous === 'solution') &&
                                        <Solution/>}
                                    </div>
                            </div>


                            <div className="slide-box section  services-box">
                                <div className='service-block' style={{height: 'auto'}}>
                                    {(this.state.show === 'services' || this.state.previous === 'services') &&
                                    <Services/>}
                                </div>
                            </div>
                            <div className='slide-box section  vision-box'>
                                <div style={{'height': '100%'}}>
                                    {(this.state.show === 'vision' || this.state.previous === 'vision') && <Vision/>}
                                </div>
                            </div>
                            <div className='slide-box section  franchising-box'>
                                <div className='franchising-block'>
                                    {(this.state.show === 'franchising' || this.state.previous === 'franchising') &&
                                    <Franchising/>}
                                </div>
                            </div>
                            <div className='slide-box section  contact-box' >
                                <div className='contact-block' style={{height: 'auto'}}>
                                    <Contact rebuild={this.state.rebuild}/>
                                </div>
                            </div>

                        </ReactFullpage.Wrapper>
                    );
                }}
            />
        );
    }
}

export default FullpageLayout;
